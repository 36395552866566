
import { FetcherResponse } from 'interfaces/fetcher';
import axiosInstance from 'utils/fetcher';

import { ImportRequestRequest, ImportRequestResponse } from './interfaces';

const getImportRequest = async (params: ImportRequestRequest): Promise<FetcherResponse<ImportRequestResponse[]>> => {
    const { data: responseData } = await axiosInstance.get<FetcherResponse<ImportRequestResponse[]>>(
        '/api/v1/mockup-design-sheet/get',
        {
            params
        }
    );

    return responseData;
};

export default getImportRequest;
