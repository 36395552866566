import { FetcherResponse } from 'interfaces/fetcher'
import axiosInstance from 'utils/fetcher'

import { ImportGoogleSheetParams, ImportGoogleSheetResponseData } from './interfaces'

const importGoogleSheet = async (
  params: ImportGoogleSheetParams,
): Promise<FetcherResponse<ImportGoogleSheetResponseData>> => {
  const  {data: responseData} = await axiosInstance.post<FetcherResponse<ImportGoogleSheetResponseData>>('/api/v1/google/sheet', params)


  return responseData
}

export default importGoogleSheet
