import {
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    Outlet,
    Route,
    RouterProvider
} from 'react-router-dom';
import { ROUTE_PATHS } from 'constants-es';

import { useAuth } from 'data/store/auth/use-auth';
import ForgotPassword from 'modules/auth/pages/forgot-password';
import RecoveryPassword from 'modules/auth/pages/recovery-password';
import Login from 'modules/auth/pages/sign-in';
import SignUp from 'modules/auth/pages/sign-up';
import WaitingAuthMsChecking from 'modules/auth/pages/waiting-authen';
import CreateDetail from 'modules/create/pages/create-detail';
import CreateList from 'modules/create/pages/create-listing';
import ImportRequest from 'modules/import-request/pages/import-request';
import MockupListing from 'modules/mockup-detail/pages/mockup-listing';
import UserListingRoot from 'modules/users/pages/user-listing';
import PrivateLayout from 'components/layouts/private-layout';


const AppRoutes = () => {
    const { isAuthenticated } = useAuth();

    const router = createBrowserRouter(
        createRoutesFromElements(
            isAuthenticated ? (
                <Route
                    element={
                        <PrivateLayout>
                            <Outlet />
                        </PrivateLayout>
                    }
                >
                    <Route path={ROUTE_PATHS.CREATE_IMAGINE} element={<CreateList />} />
                    <Route path={ROUTE_PATHS.USERS} element={<UserListingRoot />} />
                    <Route path={ROUTE_PATHS.DETAIL_IMAGINE} element={<CreateDetail />} />
                    <Route path={ROUTE_PATHS.MOCKUP_DETAIL_LIST} element={<MockupListing />} />
                    <Route path={ROUTE_PATHS.IMPORT_REQUEST_LIST} element={<ImportRequest />} />

                    <Route path={'*'} element={<Navigate to={ROUTE_PATHS.CREATE_IMAGINE} />} />
                    <Route path={ROUTE_PATHS.SIGN_IN} element={<Navigate to={ROUTE_PATHS.CREATE_IMAGINE} />} />
                </Route>
            ) : (
                <Route>
                    <Route path={ROUTE_PATHS.SIGN_IN} element={<Login />} />
                    <Route path={ROUTE_PATHS.SIGN_UP} element={<SignUp />} />
                    <Route path={ROUTE_PATHS.FORGOT_PASSWORD} element={<ForgotPassword />} />
                    <Route path={ROUTE_PATHS.RECOVERY_PASSWORD} element={<RecoveryPassword />} />
                    <Route path={ROUTE_PATHS.WAITING_AUTH_MS} element={<WaitingAuthMsChecking />} />
                    <Route path={'/*'} element={<Navigate to={ROUTE_PATHS.SIGN_IN} />} />
                </Route>
            )
        )
    );

    return <RouterProvider router={router} />;
};

export default AppRoutes;
