import { Link } from 'react-router-dom';
import { ROUTE_PATHS } from 'constants-es';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { BsThreeDots } from 'react-icons/bs';

interface GetTableColumnsConfigProps {
    isSmallScreen: boolean;
    onAction?: (key: 'Delete' | 'Edit', record: any) => void;
}

export const getTableColumnsConfig = ({ isSmallScreen, onAction }: GetTableColumnsConfigProps) => {
    const columnConfig: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: 100,

            render: (_: any, record: any) => {
                return isSmallScreen ? (
                    <Popover
                        content={
                            <div className="flex gap-2">
                                <Button
                                    onClick={() => onAction?.('Edit', record)}
                                    icon={<EditOutlined />}
                                    type="primary"
                                ></Button>
                                <Button
                                    icon={<DeleteOutlined />}
                                    type="primary"
                                    danger
                                    onClick={() => onAction?.('Delete', record)}
                                ></Button>
                            </div>
                        }
                        placement="bottom"
                        trigger="click"
                    >
                        <Button
                            type="default"
                            icon={
                                <BsThreeDots
                                    style={{
                                        verticalAlign: 'middle'
                                    }}
                                />
                            }
                        ></Button>
                    </Popover>
                ) : (
                    <div className="flex gap-2">
                        <Button
                            onClick={() => onAction?.('Edit', record)}
                            icon={<EditOutlined />}
                            type="primary"
                        ></Button>
                        <Button
                            icon={<DeleteOutlined />}
                            type="primary"
                            danger
                            onClick={() => onAction?.('Delete', record)}
                        ></Button>
                    </div>
                );
            }
        }
    ];

    return columnConfig;
};
