import { useEffect, useState } from 'react'

import { Button, Card, Col, Empty, Row, Spin } from 'antd'
import { IoChevronDown, IoChevronUp } from 'react-icons/io5'
import { MdPageview } from 'react-icons/md'
import { isEmpty } from 'lodash'

import { ImageItem, RequestListResponseData } from 'modules/create/pages/create-listing/data/queries'
import { useQueryAdvanceImgList } from 'modules/mockup-detail/data/queries/use-query-advance-img-list'
import Fancybox from 'components/ui/fancybox'

import ImageItemChecked from '../image-item-checked'

type Props = {
    item?: RequestListResponseData
    query?: any
    onClickItem?: (id: number) => void
    onSetQuery?: (query?: any, item?: ImageItem) => void
}

const AdvanceImgList = (props: Props) => {
    const { item, query, onClickItem, onSetQuery } = props

    const [openListAdvanceImg, setOpenListAdvanceImg] = useState<boolean>()

    const fetchAdvanceImg = useQueryAdvanceImgList({ enabled: !!openListAdvanceImg, mj_id: item?.idMj, ...query })

    useEffect(() => {
        setOpenListAdvanceImg(!isEmpty(query))
    }, [query])

    const handleOpenListAdvanceImg = () => {
        setOpenListAdvanceImg(!openListAdvanceImg)
        onSetQuery?.(null)
    }

    return (
        <Row gutter={[16, 16]} style={{ margin: 0 }}>
            <Col span={24}>
                <div className='text-end'>
                    <Button
                        size='small'
                        type="primary"
                        ghost
                        onClick={handleOpenListAdvanceImg}
                        icon={openListAdvanceImg ? <IoChevronUp className='me-0' /> : <IoChevronDown className='me-0' />}
                    >
                        Advance
                    </Button>
                </div>
            </Col>
            {openListAdvanceImg &&
                <Col span={24}>
                    <Card size='small' title="Advance Images">
                        <Spin spinning={fetchAdvanceImg?.isLoading}>
                            <Row gutter={[16, 16]}>
                                {
                                    isEmpty(fetchAdvanceImg?.data?.data) ?
                                        <div className='flex justify-center w-full'>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        </div>
                                        :
                                        fetchAdvanceImg?.data?.data?.map((item, index) => {
                                            return <Col span={12} key={index}>
                                                <Fancybox
                                                    options={{
                                                        Carousel: {
                                                            initialPage: 1,
                                                            infinite: false,

                                                        },
                                                    }}
                                                >
                                                    <Card
                                                        size='small'
                                                        title={item?.optionsMode}
                                                        extra={
                                                            <span data-fancybox="gallery" style={{ cursor: 'pointer' }} className="fancybox">
                                                                <MdPageview size={30} color='#3c44b1' />
                                                            </span>
                                                        }
                                                    >
                                                        <div
                                                            className='h-full relative shrink-0 grid w-auto items-start justify-end gap-[1px] lg:gap-2 rounded-md lg:rounded-xl overflow-hidden'
                                                            style={{
                                                                gridTemplateColumns: 'repeat(4, minmax(0px, 1fr))',
                                                                gridTemplateRows: 'repeat(1, minmax(0px, 1fr))',
                                                                gap: 12,
                                                                padding: 8
                                                            }}
                                                        >

                                                            {isEmpty(item?.haveChild) ?
                                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                                :
                                                                item?.haveChild?.map((child, index) => {
                                                                    return <ImageItemChecked
                                                                        key={index}
                                                                        imageItem={child as ImageItem}
                                                                        minHeight={180}
                                                                        onCheck={onClickItem}
                                                                    />
                                                                })}

                                                        </div>
                                                    </Card>
                                                </Fancybox>
                                            </Col>
                                        })}
                            </Row>
                        </Spin>
                    </Card>
                </Col>
            }
        </Row>
    )
}

export default AdvanceImgList