import { Link } from 'react-router-dom';

import { Col, FormListFieldData, Image, Row, Tooltip } from 'antd'
import { RxOpenInNewWindow } from "react-icons/rx";

import { RequestDetailResponse } from '../../../data/queries'
import FormCreateDetail from './form'

type Props = {
    item?: RequestDetailResponse
    field: FormListFieldData
}

const ItemContent = (props: Props) => {
    const { item, field } = props

    return (
        <Row gutter={[16, 16]}>
            <Col span={16} className='flex items-center justify-center'>
                <div className='flex justify-center relative'>
                    <Image
                        src={item?.image}
                        className='rounded-xl'
                        height={320}
                        style={{ minWidth: 320, objectFit: 'cover' }}
                        preview={{
                            mask:
                                <div className='relative w-full h-full'>
                                    <div
                                        className='absolute w-10 h-7 top-3 right-4 bg-[#ccc] 
                                    flex justify-center items-center hover:opacity-80 transition-all cursor-pointer'
                                    >
                                        <Tooltip title='Preview on new tab'>
                                            <Link to={item?.image || ''} target='_blank'>
                                                <RxOpenInNewWindow
                                                    className='text-[#504f4f] mt-1'
                                                    size={20}
                                                />
                                            </Link>
                                        </Tooltip>
                                    </div>
                                </div>,
                            visible: false,
                            maskClassName: 'rounded-lg bg-mask-overlay',
                        }}
                    />
                </div>
            </Col>
            <Col span={8}>
                <FormCreateDetail
                    item={item}
                    field={field}
                />
            </Col>
        </Row >
    )
}

export default ItemContent