import { RequestListResponseData } from 'modules/create/pages/create-listing/data/queries'
import CustomTag, { tailwindTagStatus } from 'components/ui/tag'

import MarkErrorImage from './mark-error'

type Props = {
    item?: RequestListResponseData
}

const PromptInfo = (props: Props) => {
    const { item } = props

    return (
        <div>
            <p className='text-[#8f94a3]'>
                {(item?.mjPrompt || item?.gptPrompt) ?
                    <>
                        <span>
                            <CustomTag
                                className={tailwindTagStatus}
                            >
                                {item?.mjPrompt ? `${item?.createMode} / ${item?.status}` : "GPT"}
                            </CustomTag>
                        </span>
                        <span>
                            {item?.mjPrompt ? item?.mjPrompt : item?.gptPrompt}
                        </span>
                    </>
                    : ''
                }
            </p>
            <MarkErrorImage item={item} />
        </div>
    )
}

export default PromptInfo