import { useMutation } from '@tanstack/react-query'

import uploadImgAws from './fetch'

export function useMutationUploadImgAws() {
  return useMutation({
    mutationFn: uploadImgAws,
  })
}

export * from './interfaces'
