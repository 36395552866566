import { useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';

import { useUIConfig } from 'data/store/ui-config/use-ui-config';
import { useQueryGetUsers } from 'modules/users/data/queries';
import { PageHeaderProvider } from 'components/core/page-header-provider';

import { ModalUser } from './components';
import { getTableColumnsConfig } from './table-config';

import 'reactflow/dist/style.css';

const UserListingRoot = () => {
    const { isSmallScreen } = useUIConfig();
    const [modalData , setModalData] =  useState<{
        open:boolean,
        data:any
    }>({
        open:false,
        data:null
    });

    const { data, isFetching } = useQueryGetUsers();

    const handleOpenModal = (e:any) => {
        setModalData({
            open:true,
            data:null
        })
    }
    const handleCancel = () => {
        setModalData({
            open:false,
            data:null
        })
    }
   
    const columns = getTableColumnsConfig({
        isSmallScreen
    });


    return (
        <div style={{}}>
            <PageHeaderProvider
                title='User Listing'
                headerTitle='User Listing'
                extra={ 
                <>
                    <Button type="primary" icon={<PlusOutlined />} onClick={handleOpenModal}>
                        Create
                    </Button>
                </> 
                } 
            />
            <Table loading={isFetching} dataSource={data?.data || []} columns={columns}></Table>
            <ModalUser open={modalData.open} data={modalData.data} onCancel={handleCancel}/>
        </div>
    );
};

export default UserListingRoot;
