import { useMutation } from '@tanstack/react-query'

import createRequest from './fetch'
import { CreateRequestParams } from './interfaces'

export function useMutationCreateRequest() {
  return useMutation({
    mutationFn: (params: CreateRequestParams)=> createRequest(params),
  })
}

export * from './interfaces'
