/* Core */
import { createLogger } from 'redux-logger'

const middleware = [
  createLogger({
    duration: true,
    timestamp: false,
    collapsed: true,
    colors: {
      title: () => '#139BFE',
      prevState: () => '#1C5FAF',
      action: () => '#149945',
      nextState: () => '#A47104',
      error: () => '#ff0005',
    },
    predicate: () => {
      return (
        process.env.NEXT_PUBLIC_ENABLE_REDUX_LOGGER === 'true' &&
        typeof window !== 'undefined'
      )
    },
  }),
]

export { middleware }
