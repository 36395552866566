import { FetcherResponse } from 'interfaces/fetcher'
import axiosInstance from 'utils/fetcher'

import { CreateRequestParams, CreateRequestResponseData } from './interfaces'

const createRequest = async (
  params: CreateRequestParams,
): Promise<FetcherResponse<CreateRequestResponseData>> => {
  const  {data: responseData} = await axiosInstance.post<FetcherResponse<CreateRequestResponseData>>('/api/v1/mockup-design-sheet/insert', params)


  return responseData
}

export default createRequest
