import React from 'react'

import { Col, Row } from 'antd'

const initCol = {
  colLabel: 12,
  colValue: 12
}

type DataProps = {
  label?: React.ReactNode
  value?: React.ReactNode
}

type ColProps = {
  colLabel: number
  colValue: number
}

type Props = {
  data: DataProps[]
  col?: ColProps
}

const DisplayGroupValue = (props: Props) => {
  const { data, col = initCol } = props
  const { colLabel, colValue } = col

  return (
    <Row gutter={[16, 16]}>
      {data.map((item, index) => {
        return <Col span={24} key={index}>
          <Row gutter={[8, 8]}>
            <Col span={colLabel}>
              <label className='font-semibold'>{item?.label ? item?.label : '--'}</label>
            </Col>
            <Col span={colValue} className='text-start'>
              <span>{item?.value ? item?.value : '--'}</span>
            </Col>
          </Row>
        </Col>
      })}
    </Row>
  )
}
export default DisplayGroupValue