import { useEffect } from 'react';

import { Col, Row } from 'antd';

import PromptBar from 'modules/create/components/prompt-bar';

import ContentDetail from './components/content';

import 'reactflow/dist/style.css';

const CreateDetail = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Row gutter={[0, 16]} className='h-full'>
            <Col span={24}>
                <PromptBar showGoBack={true} />
            </Col>
            <Col span={24} className='h-full flex items-center justify-center'>
                <ContentDetail />
            </Col>
        </Row>
    );
};

export default CreateDetail;
