import { Fragment, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { QUERY_KEYS } from 'constants-es'

import { useQueryClient } from '@tanstack/react-query'
import { Button, Card, Col, Divider, Form, message, Row, Spin } from 'antd'

import { handleTransformValueSelect } from 'utils/transform-value-select'

import { useQueryGetRequestDetail } from '../../data/queries'
import { CreateMockupParams, useMutationCreateMockup } from '../../data/queries/use-mutation-request-detail'
import ItemContent from './item'

type Props = {
    data?: any
}

const ContentDetail = (props: Props) => {
    const [form] = Form.useForm()
    const queryClient = useQueryClient()
    const [searchParams] = useSearchParams()
    const ids = Object.fromEntries(searchParams.entries())

    const mutationCreateMockup = useMutationCreateMockup()
    const fetchRequestDetail = useQueryGetRequestDetail({ enabled: !!ids?.id, ids: ids?.id?.split(',') || [] })

    const handleSubmit = async (values: any) => {
        const params: CreateMockupParams = values['form-create-detail'].map((item: any) => {
            return {
                mj_id: item?.idRequest,
                storage_id: item?.id,
                input_url: item?.image,
                template_type: handleTransformValueSelect(item?.templateType),
                product_type: handleTransformValueSelect(item?.productType),
                product_name: item?.productName,
                catalog_publish: !!item?.catalogPublish
            }
        })

        const result = await mutationCreateMockup.mutateAsync(params)
        message[result?.success ? 'success' : 'error'](result?.success ? 'Execute Success!' : 'Execute Failed!')
        if (result?.success) {
            form.resetFields(['productName'])
        }
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.REQUEST_DETAIL] })
    }

    useEffect(() => {
        const initValues = fetchRequestDetail?.data?.data?.map((items) => {
            const { templateType, productType, defaultProductName, defaultProductType, defaultTemplate, defaultPublish } = items

            return {
                ...items,
                productName: defaultProductName,
                templateType: templateType,
                productType: productType,
                catalogPublish: !!defaultPublish
            }
        })
        form.setFieldValue("form-create-detail", initValues)
    }, [form, fetchRequestDetail?.data?.data])

    return (
        <Row gutter={[16, 16]} className='w-full mt-16'>
            <Col span={24}>
                <Spin spinning={fetchRequestDetail.isLoading} tip="Loading..." className='h-100 w-full'>
                    <Row gutter={[16, 16]} className='relative mb-16'>
                        <Col span={24}>
                            <Form form={form} layout='vertical' onFinish={handleSubmit}>
                                <Form.List name="form-create-detail" initialValue={[{}]}>
                                    {(fields, { add, remove }) => {
                                        return <Row gutter={[16, 16]}>
                                            {fields.map((field, index) => {
                                                const item = fetchRequestDetail?.data?.data[index]
                                                const isLastIndex = index === Number(fetchRequestDetail.data?.data?.length) - 1

                                                return <Fragment key={index}>
                                                    <Col span={24} className='w-full'>
                                                        <ItemContent
                                                            item={item}
                                                            field={field}
                                                        />
                                                    </Col>
                                                    {!isLastIndex && <Divider className='my-1' />}
                                                </Fragment>
                                            })}
                                        </Row>
                                    }}
                                </Form.List>
                            </Form>
                        </Col>
                        <Col
                            span={24}
                            className='fixed left-[255px] bottom-0 w-[calc(100%-255px)] border-t-[1px]'
                        >
                            <Card size='small' className='relative border-none'>
                                <div className='flex items-center justify-center'>
                                    <Button
                                        type='primary'
                                        className='w-64'
                                        onClick={form.submit}
                                        loading={mutationCreateMockup?.isPending}
                                    >
                                        Execute
                                    </Button>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Spin >
            </Col>
        </Row>
    )
}

export default ContentDetail