import React, { useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from 'constants-es';

import { Button, Col, Row } from 'antd';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';
import { isEqual } from 'lodash';

import Loader from 'components/ui/loader';
import CustomTag, { tailwindTagStatus } from 'components/ui/tag';

import { ImageItem, RequestListResponseData } from '../../../data/queries';
import AdvanceImgList from './advance-img-list';
import ImageItemChecked from './image-item-checked';
import PromptInfo from './prompt-info';
import TableDetail from './table-detail';

import 'react-lazy-load-image-component/src/effects/blur.css';
import './styles.css';

type Props = {
    item?: RequestListResponseData
    selectedItems?: any[]
}

const WaitingLoadImg = ({ children, }: { children?: React.ReactNode }) => {

    return <div
        className={`bg-[#ebecef] rounded-xl flex justify-center items-center relative min-h-[286px]`}
    >
        {children}
    </div>
}

const ItemContent = (props: Props) => {
    const { item, selectedItems = [] } = props

    const navigate = useNavigate()

    const [imgAdvance, setImgAdvance] = useState<ImageItem | null>()
    const [queryAdvanceImgList, setQueryAdvanceImgList] = useState<any>()

    const handleClickItem = (id: number) => {
        navigate({
            pathname: ROUTE_PATHS.DETAIL_IMAGINE,
            search: createSearchParams({
                id: [id].join(',')
            }).toString()
        })
    }

    const handleSetQuery = (query?: any, item?: ImageItem) => {
        if (isEqual(query?.parent_grid, queryAdvanceImgList?.parent_grid)) {
            setQueryAdvanceImgList(null)
            setImgAdvance(null)

            return
        }
        setImgAdvance(item)
        setQueryAdvanceImgList(query)
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={18}>
                <div
                    className='h-full relative shrink-0 grid w-auto items-start justify-end gap-[1px] lg:gap-2 rounded-md lg:rounded-xl overflow-hidden'
                    style={{
                        gridTemplateColumns: 'repeat(4, minmax(0px, 1fr))',
                        gridTemplateRows: 'repeat(1, minmax(0px, 1fr))',
                        gap: 12,
                        padding: 8
                    }}
                >
                    {
                        !['Advanced-Completed', 'MJ-Completed'].includes(item?.status || '') ?
                            Array.from({ length: 4 }).map((_, index) => {
                                return <WaitingLoadImg key={index}>
                                    <CustomTag
                                        className={tailwindTagStatus}
                                    >
                                        {item?.status}
                                    </CustomTag>
                                    {index === 0 && <Loader />}
                                </WaitingLoadImg>
                            })
                            :
                            item?.imageItems?.map((imageItem, index: number) => {
                                const shadow = selectedItems.includes(imageItem.idStorage) ? 'shadow-[0px_0px_8px_1px_#f2330d]' : ''
                                const showAdvance = item?.hasAdvanced

                                return (
                                    <div key={index} className='relative'>
                                        <ImageItemChecked
                                            key={index}
                                            imageItem={imageItem}
                                            onCheck={handleClickItem}
                                            shadow={shadow}
                                        />
                                        {
                                            showAdvance &&
                                            <span
                                                style={{ cursor: 'pointer' }}
                                                className='bottom-0 left-2 absolute p-2'
                                            >
                                                <Button
                                                    size='small'
                                                    type={imgAdvance?.parentGrid === imageItem?.parentGrid ? 'primary' : 'default'}
                                                    ghost
                                                    onClick={() => handleSetQuery({ parent_grid: imageItem?.parentGrid }, imageItem)}
                                                    icon={imgAdvance?.parentGrid === imageItem?.parentGrid ? <IoChevronUp className='me-0' /> : <IoChevronDown className='me-0' />}
                                                >
                                                    Advance
                                                </Button>
                                            </span>
                                        }
                                    </div>
                                )
                            })
                    }
                </div>
            </Col>
            <Col span={6}>
                <div className='flex gap-2 flex-col h-full justify-between'>
                    <PromptInfo
                        item={item}
                    />
                </div>
            </Col>
            <Col span={24}>
                {!!item?.hashDetail &&
                    <TableDetail
                        item={item}
                    />
                }
            </Col>
            <Col span={24}>
                {!!item?.hasAdvanced &&
                    <AdvanceImgList
                        onClickItem={handleClickItem}
                        item={item}
                        query={queryAdvanceImgList}
                        onSetQuery={handleSetQuery}
                    />
                }
            </Col>
        </Row >
    )
}

export default ItemContent