import { QUERY_KEYS } from 'constants-es';

import { useQuery } from '@tanstack/react-query';

import getUserDetail from './fetch';

export function useQueryGetUserDetail(id:any) {
    return useQuery({
        queryKey: [QUERY_KEYS.USER_DETAIL, id],
        queryFn: () => getUserDetail({id}),
        enabled:id
    });
}

export * from './interfaces';

