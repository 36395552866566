import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ScrollPositionState, SetScrollPositionPayload } from './interface'

const initialState: ScrollPositionState = {
  scrollPosition: 0,
  scrollLength: 0,
}

export const scrollPositionSlice = createSlice({
  name: 'scrollPosition',
  initialState,
  reducers: {
    setScrollPosition: (state, action: PayloadAction<SetScrollPositionPayload>) => {
      state.scrollPosition = action.payload.scrollPosition
      state.scrollLength = action.payload.scrollLength
    },
  },
})

export const { setScrollPosition } = scrollPositionSlice.actions
