import { QUERY_KEYS } from 'constants-es'

import { useQueryClient } from '@tanstack/react-query'
import { Button, Image, message, Tooltip } from 'antd'
import { AiFillWarning } from 'react-icons/ai'
import { IoIosCheckmarkCircleOutline } from 'react-icons/io'

import { useResponseMarkError } from 'data/store/response-mark-error/use-response-mark-error'
import { useMutationUpdateFlagError } from 'modules/create/data/mutations/use-mutation-update-flag-error'
import { RequestListResponseData } from 'modules/create/pages/create-listing/data/queries'

type Props = {
    item?: RequestListResponseData
}


const MarkErrorImage = (props: Props) => {
    const queryClient = useQueryClient()
    const { item } = props
    const stylesImgError = !!item?.designErrorFlag ? { border: '1px solid red' } : {}

    const mutationMarkError = useMutationUpdateFlagError()
    const { setResponseMarkError } = useResponseMarkError()

    const handleMarkError = async () => {
        const request: any = item?.id ? {
            ids: [item?.id],
        } : {}

        const result = await mutationMarkError.mutateAsync(request)
        if (!!result?.success) {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.REQUEST_LIST] })
            message.success(result?.message)
            setResponseMarkError({ data: result?.data })
        } else {
            message.error(result?.message)
        }
    }

    if (!item?.designUrl) return null

    return (
        <div className='flex gap-2'>
            <div
                style={stylesImgError}
                className='w-20 h-20 flex justify-center items-center rounded-lg relative'
            >
                <Image
                    src={item?.designUrl || item?.originalUrl}
                    width={68}
                    height={68}
                />
                {
                    !!item?.designErrorFlag &&
                    <Tooltip title='Design Error'>
                        <span className='absolute top-[0px] right-[2px]'>
                            <AiFillWarning color='red' />
                        </span>
                    </Tooltip>
                }
            </div>
            {!item?.designErrorFlag &&
                <div className='mt-1'>
                    <Button
                        size='small'
                        type="primary"
                        danger
                        icon={<IoIosCheckmarkCircleOutline className='me-0' />}
                        loading={mutationMarkError.isPending}
                        onClick={handleMarkError}
                    >
                        Mark Error
                    </Button>
                </div>
            }
        </div>
    )
}

export default MarkErrorImage