import { ROUTE_PATHS, SidebarMenuItemType } from 'constants-es';

import { CiImport } from 'react-icons/ci';

import { SidebarMenuItem } from 'interfaces/sidebar-menu-item';

export const importRequestSidebarConfig: SidebarMenuItem[] = [
    {
        id: 'import-request',
        label: 'Requirements',
        path: ROUTE_PATHS.IMPORT_REQUEST_LIST,
        type: SidebarMenuItemType.Item,
        icon: <CiImport />,
        permissions: [],
        children: []
    }
];
