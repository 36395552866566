import { useState } from 'react';
import { QUERY_KEYS } from 'constants-es';

import { PlusOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Checkbox, Col, Form, Input, message, Modal, Row } from 'antd';
import { isEmpty } from 'lodash';

import ChangeCase from 'utils/change-case';
import { messageErr } from 'utils/error-message';
import { handleTransformGroupValuesSelect } from 'utils/transform-value-select';
import { validateWhiteSpace } from 'utils/validate';
import { defaultPrompt } from 'modules/create/components/prompt-bar';
import { listProductType, listTemplateType } from 'modules/create/pages/create-detail/components/content/item/form';
import { CreateRequestParams, useMutationCreateRequest } from 'modules/import-request/data/mutations/use-mutation-create-request';
import SelectOption from 'components/ui/select-option';

const listOptionsMode = [
    {
        label: 'VarySubtle',
        value: 'VarySubtle'
    },
    {
        label: 'UpScaleSubtle',
        value: 'UpScaleSubtle'
    },
    {
        label: 'VaryStrong',
        value: 'VaryStrong'
    }
]

const listSpeedMode = [
    {
        label: 'Relaxed',
        value: 'relaxed'
    },
    {
        label: 'Fast',
        value: 'fast'
    },
    {
        label: 'Turbo',
        value: 'turbo'
    }
]
const listCreateMode = [
    {
        label: 'Normal',
        value: 'Normal'
    },
    {
        label: 'Mockup',
        value: 'Mockup'
    },
    {
        label: 'Advanced',
        value: 'Advanced'
    },
]

const ModalCreateRequest = () => {
    const [form] = Form.useForm();
    const queryClient = useQueryClient();

    const [open, setOpen] = useState(false);

    const mutationCreateRequest = useMutationCreateRequest();

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = async (values: any) => {
        const { defaultPublish, optionsMode, ...rest } = values

        const request: CreateRequestParams = {
            actions: 'insert',
            data: [ChangeCase.object.toSnakeCase(handleTransformGroupValuesSelect({
                ...rest,
                optionsMode: isEmpty(optionsMode) ? null : optionsMode.map((item: any) => item?.value).join(','),
                defaultPublish: !!defaultPublish
            }))]
        }

        const result = await mutationCreateRequest.mutateAsync(request);
        if (!!result?.success) {
            message.success(result.message);
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.IMPORT_REQUEST] })
            setOpen(false);
        } else {
            message.error(result.message);
        }
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleAfterClose = () => {
        form.resetFields();
    }

    return (
        <>
            <Button
                onClick={showModal}
                icon={<PlusOutlined size={18} />}
                type="primary"
            >
                Create
            </Button>
            <Modal
                title="Create Request"
                open={open}
                onOk={form.submit}
                confirmLoading={mutationCreateRequest.isPending}
                onCancel={handleCancel}
                afterClose={handleAfterClose}
                destroyOnClose={true}
                maskClosable={false}
                width={800}
            >
                <Form layout='vertical' onFinish={handleOk} form={form}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item
                                name="productName"
                                label="Product Name"
                                rules={[{ required: true, message: messageErr.required }, () => validateWhiteSpace()]}
                            >
                                <Input placeholder='Please enter product name...' />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="originalUrl"
                                label="Original URL"
                                rules={[{ required: true, message: messageErr.required }, () => validateWhiteSpace()]}
                            >
                                <Input placeholder='Please enter original url...' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='defaultTemplate'
                                label="Default Template"
                                rules={[
                                    {
                                        required: true,
                                        message: messageErr.required
                                    }
                                ]}
                            >
                                <SelectOption
                                    options={listTemplateType}
                                    placeholder='Please select default template...'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='defaultProductType'
                                label="Default Product Type"
                                rules={[
                                    {
                                        required: true,
                                        message: messageErr.required
                                    }
                                ]}
                            >
                                <SelectOption
                                    options={listProductType}
                                    placeholder='Please select default product type...'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='optionsMode'
                                label="Options Mode"
                            >
                                <SelectOption
                                    options={listOptionsMode}
                                    placeholder='Please select options mode...'
                                    mode='multiple'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='speedMode'
                                label="Speed Mode"
                                rules={[
                                    {
                                        required: true,
                                        message: messageErr.required
                                    }
                                ]}
                            >
                                <SelectOption
                                    options={listSpeedMode}
                                    placeholder='Please select speed mode...'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='createMode'
                                label="Create Mode"
                                rules={[
                                    {
                                        required: true,
                                        message: messageErr.required
                                    }
                                ]}
                            >
                                <SelectOption
                                    options={listCreateMode}
                                    placeholder='Please select create mode...'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name='defaultPrompt'
                                label="Default Prompt"
                                initialValue={defaultPrompt}
                                rules={[
                                    {
                                        required: true,
                                        message: messageErr.required
                                    }
                                ]}
                            >
                                <Input.TextArea placeholder='Please enter default prompt...' rows={3} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name='defaultPublish'
                                label=""
                                valuePropName='checked'
                            >
                                <Checkbox>
                                    <span className='ms-2'>Default Publish</span>
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default ModalCreateRequest