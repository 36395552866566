import { QUERY_KEYS } from 'constants-es';

import { useQuery } from '@tanstack/react-query';

import getUsers from './fetch';

export function useQueryGetUsers(enabled: boolean = true) {
    return useQuery({
        queryKey: [QUERY_KEYS.WORKFLOWS],
        queryFn: getUsers,
    });
}

export * from './interfaces';
