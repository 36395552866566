import axios, { AxiosResponse } from 'axios'
import { ROUTE_PATHS } from 'constants-es';

import { message } from 'antd';

import { getAuth } from 'helpers/auth';

import ChangeCase from './change-case'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN,
 
  // timeout: 20000,
  // withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'apikey': process.env.REACT_APP_API_KEY,
  },
})

axiosInstance.interceptors.response.use(
  (response) => {
    
    if (response.status > 300 || response.status < 200) {
      const newResponse:any =  {
        ...response
      }
      
      return Promise.reject(
        new Error(ChangeCase.object.toCamelCase(newResponse)),
      )
    }
    const newResponse:AxiosResponse =  {
      ...response
    }
    
return ChangeCase.object.toCamelCase(newResponse)
  },
  (error) => {
  
    console.log(error , 'error');
    // return Promise.reject(new Error(error.message))
    if(error.response?.status === 401 && ![ROUTE_PATHS.SIGN_IN, ROUTE_PATHS.RECOVERY_PASSWORD].includes(window.location.pathname)){
      message.error("Session Expired")
      localStorage.clear()
      window.location.href = ROUTE_PATHS.SIGN_IN
      
      return;
    }

    if (error.response) {
      return Promise.resolve(ChangeCase.object.toCamelCase({
        ...error.response,
        data:{
          success: false,
          data: null,
          message: error.response.data.error_description || error.response.data.msg || error.response.data.message || "Request Failed",
          error:error.response.data
        }
      }))
    }

    return Promise.reject(error)
  },
)

axiosInstance.interceptors.request.use((config) => {
  const auth = getAuth();
  if (auth) {
      config.headers.Authorization = `Bearer ${auth}`;
  }
  if (
    !(config.headers['Content-Type'] as string)?.includes('multipart/form-data')
  ) {
    // config.data = ChangeCase.object.toSnakeCase(config.data)
  }

  // config.params = ChangeCase.object.toSnakeCase(config.params)

  return config
})

export default axiosInstance
