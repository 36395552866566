import { QUERY_KEYS } from 'constants-es'

import { useQuery } from '@tanstack/react-query'

import createGetTokenMsLogin, { TCreateGetTokenMsLogin } from './fetch'

type TUseGetTokenMs = {
  enabled?: boolean
} & TCreateGetTokenMsLogin

export function useQueryGetTokenMs(params: TUseGetTokenMs) {
  const { enabled = true,...rest } = params
  
return useQuery({
    queryFn:()=> createGetTokenMsLogin(rest),
    queryKey: [QUERY_KEYS.GET_TOKEN_LOGIN_MS, rest],
  })
}
