import { QUERY_KEYS } from 'constants-es';

import { useQuery } from '@tanstack/react-query';

import ChangeCase from 'utils/change-case';

import getRequestDetail from './fetch';
import { RequestDetailParams } from './interfaces';

export type TUseGetRequestDetail = RequestDetailParams & { enabled?: boolean }

export function useQueryGetRequestDetail({ enabled = true, ...rest }: TUseGetRequestDetail) {
    return useQuery({
        queryKey: [QUERY_KEYS.REQUEST_DETAIL, rest],
        queryFn: () => getRequestDetail(rest),
        select: (res) => {
            return {
                ...res,
                data: ChangeCase.object.toCamelCase(res?.data)
            }
        },
        enabled
    });
}

export * from './interfaces';
