import { useEffect, useState } from 'react';
import { QUERY_KEYS } from 'constants-es';

import { useQueryClient } from '@tanstack/react-query';
import { Button, Form, Input, message, Modal } from 'antd';
import { CiImport } from 'react-icons/ci';

import { messageErr } from 'utils/error-message';
import { validateWhiteSpace } from 'utils/validate';
import { useMutationImportGoogleSheet } from 'modules/create/data/queries/use-mutation-import-gg-sheet';

const ImportGoogleSheet = () => {
    const [form] = Form.useForm();
    const queryClient = useQueryClient()

    const [open, setOpen] = useState(false);

    const mutationGoogleSheet = useMutationImportGoogleSheet();

    // useEffect(() => {
    //     if (open) {
    //         document.body.classList.add('ant-modal-open-cs');
    //     } else {
    //         document.body.classList.remove('ant-modal-open-cs');
    //     }
    // }, [open])

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = async (values: any) => {
        const request = {
            url: values.url
        }
        const result = await mutationGoogleSheet.mutateAsync(request);
        if (!!result?.success) {
            message.success(result.message);
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.REQUEST_LIST] })
            setOpen(false);
        } else {
            message.error(result.message);
        }
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleAfterClose = () => {
        form.resetFields();
    }

    return (
        <>
            <Button
                onClick={showModal}
                icon={<CiImport size={18} />}
                ghost
                type="primary"
            >
                Import
            </Button>
            <Modal
                title="Import Google Sheet"
                open={open}
                onOk={form.submit}
                confirmLoading={mutationGoogleSheet.isPending}
                onCancel={handleCancel}
                afterClose={handleAfterClose}
                destroyOnClose={true}
                maskClosable={false}
            >
                <Form layout='vertical' onFinish={handleOk} form={form}>
                    <Form.Item
                        name="url"
                        label="Google Sheet URL"
                        rules={[{ required: true, message: messageErr.required }, () => validateWhiteSpace()]}
                    >
                        <Input placeholder='Please enter google sheet url...' />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ImportGoogleSheet