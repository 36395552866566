import { JSXElementConstructor, ReactElement, useMemo } from 'react';

import { Select } from 'antd'
import { DefaultOptionType } from 'antd/es/select';
import { SelectProps } from 'antd/lib';
import { AiOutlineSync } from 'react-icons/ai';
import { isArray } from 'lodash';

import { convertNormalizeString } from 'utils/convert-normalize';

export type SelectOptionProps = SelectProps<DefaultOptionType, Omit<DefaultOptionType, 'options'>> & {
    options?: any[];
    children?: React.ReactNode;
    onChange?: (value: any) => void
    placeholder?: string
    loading?: boolean
    nullValue?: boolean
    dropdownRender?: (menu: ReactElement<any, string | JSXElementConstructor<any>>) => React.ReactNode;
};
const CustomLoadingIndicator = () => {
    return <AiOutlineSync style={{ verticalAlign: '-0.15rem' }}

        className='rotate-infinite'
    />
};
const SelectOption = ({ loading, children, options, style, onChange, placeholder, dropdownRender, nullValue = false, ...restProps }: SelectOptionProps) => {

    return (
        <Select
            allowClear
            showSearch
            options={options}
            fieldNames={restProps?.fieldNames}
            dropdownRender={dropdownRender}
            suffixIcon={loading ? <CustomLoadingIndicator /> : undefined}
            onChange={(_value, option) => {
                if (!!restProps?.fieldNames && restProps?.fieldNames.hasOwnProperty('value') && restProps?.fieldNames.hasOwnProperty('label')) {
                    if (isArray(option)) {
                        option = option.map((item: any) => {
                            return {
                                ...item,
                                value: item[restProps?.fieldNames?.value || "value"],
                                label: item[restProps?.fieldNames?.label || "label"]
                            }
                        })
                    } else if (!!option) {
                        option = {
                            ...option,
                            value: option[restProps?.fieldNames?.value || "value"],
                            label: option[restProps?.fieldNames?.label || "label"]
                        }
                    }
                }

                const newOption = nullValue ? (option || null) : option
                onChange && onChange(newOption)
            }}
            filterOption={(input: any, option: any) => {
                const label = option[restProps?.fieldNames?.label || "label"];
                const oriString = convertNormalizeString(label)
                const stringToCompare = convertNormalizeString(input)

                const result = (`${oriString ?? ''}`?.toLowerCase() ?? '').indexOf(stringToCompare?.toLowerCase()) > -1;

                return result
            }}
            disabled={restProps?.disabled}
            value={restProps?.value}
            mode={restProps?.mode}
            maxTagCount={restProps?.maxTagCount}
            placeholder={placeholder}
            children={children}
            loading={loading}
            onFocus={restProps?.onFocus}
            onBlur={restProps?.onBlur}
            style={{
                width: '100%',
                ...style
            }}
        />
    )
}

export default SelectOption