
import { useMutation } from '@tanstack/react-query';

import createRequestMockup from './fetch';
import { CreateMockupParams } from './interfaces';

export type TUseGetRequestDetail = CreateMockupParams & { enabled?: boolean }

export function useMutationCreateMockup() {
    return useMutation({
        mutationFn: createRequestMockup,
    });
}

export * from './interfaces';
