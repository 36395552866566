import { useEffect } from 'react';

import { Col, Row } from 'antd';

import PromptBar from '../../components/prompt-bar';
import ContentPage from './components/content';

import 'reactflow/dist/style.css';


const CreateList = () => {

    return (
        <Row gutter={[0, 16]}>
            <Col span={24}>
                <PromptBar />
            </Col>
            <Col span={24} className='mt-20'>
                <ContentPage />
            </Col>
        </Row>
    );
};

export default CreateList;
