import { QUERY_KEYS } from 'constants-es';

import { useInfiniteQuery } from '@tanstack/react-query';

import { FetcherResponse } from 'interfaces/fetcher';
import ChangeCase from 'utils/change-case';

import getRequestList from './fetch';
import { RequestListParams, RequestListResponseData } from './interfaces';


export function useQueryRequestList({enabled = true,...rest}: RequestListParams & { enabled?: boolean} = {}) {
  return useInfiniteQuery({
    queryKey: [QUERY_KEYS.REQUEST_LIST, rest],
    queryFn: ({ pageParam = 1 }) => {
      return getRequestList({ ...rest, page: pageParam});
    },
    getNextPageParam: (lastPage: any) => {
      const maxPages = Number(lastPage?.pagination?.totalPage);
      const nextPage = Number(lastPage?.pagination?.page) + 1;
      const page = maxPages >= nextPage ? nextPage : undefined;
      
      return page
    },
    select: (res) => {
      const tempData = res?.pages?.map((item: FetcherResponse<RequestListResponseData[]>) => {
        const newItem =  {...item, data: ChangeCase.object.toCamelCase(item.data)}
        
        return newItem;
      });
      
      return {
        ...res,
        pages: tempData.reduce((acc: any, cur: any) => acc.concat(cur), [])
      }
    },
    initialPageParam: 1,
    refetchInterval: 10000,
    refetchIntervalInBackground: true,
    enabled: true,
    placeholderData: (previousData, _previousQuery) => previousData,
  })
}

export * from './interfaces';

