import { messageErr } from "./error-message";

type TValidDateWhiteSpace = {
    require?: boolean;
    message?: string;
};

export const validateWhiteSpace = ({ require = true, message }: TValidDateWhiteSpace = {}) => {
    return {
        validator(_rule: any, value: any, callback: (error?: string) => void) {
            if (require) {
                if (typeof value === 'string' && !!value && !value.trim()) {
                    return Promise.reject(new Error(message || messageErr.whiteSpace));
                }

                return Promise.resolve();
            } else {
                return Promise.resolve();
            }
        }
    };
};