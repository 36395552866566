import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'libs/redux'

import { ResponseMarkErrorState, SetResponseMarkErrorStatePayload } from './interface'
import { setResponseMarkError } from './slice'

interface UseResponseMarkError extends ResponseMarkErrorState {
  setResponseMarkError: (payload: SetResponseMarkErrorStatePayload) => void
}

export const useResponseMarkError = (): UseResponseMarkError => {
  const responseMarkError = useAppSelector((state) => state.responseMarkError)
  const dispatch = useAppDispatch()

  const handleSetResponseMarkError = useCallback(
    (payload: SetResponseMarkErrorStatePayload) => {
      dispatch(setResponseMarkError(payload))
    },
    [dispatch],
  )

  return {
    ...responseMarkError,
    setResponseMarkError: handleSetResponseMarkError,
  }
}
