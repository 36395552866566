import { FetcherResponse } from 'interfaces/fetcher';
import axiosInstance from 'utils/fetcher';

import { RequestDetailParams, RequestDetailResponse } from './interfaces';

const getRequestDetail = async (params?: RequestDetailParams): Promise<FetcherResponse<RequestDetailResponse[]>> => {
    const { data: responseData } = await axiosInstance.post<FetcherResponse<RequestDetailResponse[]>>(
        '/api/v1/mockup-design-storage/list',
        params
    );

    return responseData;
};

export default getRequestDetail;
