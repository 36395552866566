import { FetcherResponse } from 'interfaces/fetcher'
import axiosInstance from 'utils/fetcher'

import { UpdatePublishParams, UpdatePublishResponseData } from './interfaces'

const updatePublish = async (
  params: UpdatePublishParams,
): Promise<FetcherResponse<UpdatePublishResponseData>> => {
  const  {data: responseData} = await axiosInstance.put<FetcherResponse<UpdatePublishResponseData>>(`/api/v1/mockup-design/detail/publish`, params)


  return responseData
}

export default updatePublish
