import { Link } from 'react-router-dom';

import { Col, Image, Row } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { isArray, isEmpty } from 'lodash';

import { ImportRequestResponse } from 'modules/import-request/data/queries';
import Copy from 'components/ui/copy';
import DisplayGroupValue from 'components/ui/display-group-column';
import CustomTag, { tailwindTagStatus } from 'components/ui/tag';

import ColumnPublish from './components/column-public';

interface GetTableColumnsConfigProps {
    isSmallScreen: boolean;
    onAction?: (key: 'Delete' | 'Edit', record: any) => void;
}

export const getTableColumnsConfig = ({ isSmallScreen, onAction }: GetTableColumnsConfigProps) => {
    const columnConfig: ColumnsType<ImportRequestResponse> = [
        {
            title: <div className='text-center'>ID</div>,
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 100
        },
        {
            title: <div className='text-center'>Original Image</div>,
            dataIndex: 'originalImage',
            key: 'original_image',
            align: 'center',
            width: 150,
            render: (_value: any, record: ImportRequestResponse) => {
                return <Row gutter={[0, 8]}>
                    <Col span={24}>
                        <Image src={record?.originalUrl} width={106} height={106} />
                    </Col>
                    <Col span={24}>
                        <div className='truncate w-[150px]'>
                            <Link to={record?.originalUrl || ''} target='_blank'>
                                {record?.originalUrl}
                            </Link>
                        </div>
                    </Col>
                </Row>
            }
        },
        {
            title: <div className='text-center'>Product Name</div>,
            dataIndex: 'productName',
            key: 'product_name',
            align: 'left',
            width: 200,
            render: (value: ImportRequestResponse['productName']) => {
                if (!value) return null

                return <div>{value}</div>
            }
        },
        {
            title: <div className='text-center'>Prompt</div>,
            dataIndex: 'defaultPrompt',
            key: 'default_prompt',
            align: 'left',
            render: (value: ImportRequestResponse['defaultPrompt']) => {
                if ((!value)) return null

                return <Copy text={value}>
                    <span>{value}</span>
                </Copy>
            }
        },
        {
            title: <div className='text-center'>Template Name</div>,
            dataIndex: 'defaultTemplate',
            key: 'default_template',
            align: 'center',
            width: 100,
            render: (value: ImportRequestResponse['defaultTemplate']) => {
                if (!value) return null

                return <div>{value}</div>
            }
        },
        {
            title: <div className='text-center'>Product Type</div>,
            dataIndex: 'defaultProductType',
            key: 'default_product_type',
            align: 'center',
            width: 100,
            render: (value: ImportRequestResponse['defaultProductType']) => {
                if (!value) return null

                return <div>{value}</div>
            }
        },
        {
            title: <div className='text-center'>Mode</div>,
            dataIndex: 'mode',
            key: 'mode',
            align: 'center',
            width: 200,
            render: (_value: any, record: ImportRequestResponse) => {
                const newOptionsMode = record?.optionsMode?.split(',') || []
                const data = [
                    {
                        label: 'Options:',
                        value: <div className='flex flex-wrap gap-1'>
                            {
                                (isArray(newOptionsMode) && !isEmpty(newOptionsMode)) ?
                                    newOptionsMode.map((item, index) => {
                                        return <span key={index}>
                                            <CustomTag>
                                                {item}
                                            </CustomTag>
                                        </span>
                                    })
                                    : '--'
                            }
                        </div>
                    },
                    {
                        label: 'Create:',
                        value: <CustomTag value={record?.createMode} />
                    },
                    {
                        label: 'Speed:',
                        value: <CustomTag value={record?.speedMode} />
                    },
                ]

                return <DisplayGroupValue
                    data={data}
                    col={{ colLabel: 9, colValue: 15 }}
                />
            }
        },
        {
            title: <div className='text-center'>Import Type</div>,
            dataIndex: 'importType',
            key: 'import_type',
            align: 'center',
            width: 100,
            render: (value: ImportRequestResponse['importType']) => {
                if (!value) return null

                return <div>{value}</div>
            }
        },
        {
            title: <div className='text-center'>Status</div>,
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: 100,
            render: (value: ImportRequestResponse['status']) => {
                if (!value) return null

                return <CustomTag value={value} className={tailwindTagStatus} />
            }
        },
        {
            title: <div className='text-center'>Catalog Public</div>,
            dataIndex: 'defaultPublish',
            key: 'default_publish',
            align: 'center',
            width: 150,
            render: (_value: ImportRequestResponse['defaultPublish'], record: ImportRequestResponse) => {

                return <ColumnPublish record={record} />
            }
        },
        // {
        //     title: 'Actions',
        //     dataIndex: 'actions',
        //     key: 'actions',
        //     width: 100,

        //     render: (_: any, record: any) => {
        //         return isSmallScreen ? (
        //             <Popover
        //                 content={
        //                     <div className="flex gap-2">
        //                         <Button
        //                             onClick={() => onAction?.('Edit', record)}
        //                             icon={<EditOutlined />}
        //                             type="primary"
        //                         ></Button>
        //                         <Button
        //                             icon={<DeleteOutlined />}
        //                             type="primary"
        //                             danger
        //                             onClick={() => onAction?.('Delete', record)}
        //                         ></Button>
        //                     </div>
        //                 }
        //                 placement="bottom"
        //                 trigger="click"
        //             >
        //                 <Button
        //                     type="default"
        //                     icon={
        //                         <BsThreeDots
        //                             style={{
        //                                 verticalAlign: 'middle'
        //                             }}
        //                         />
        //                     }
        //                 ></Button>
        //             </Popover>
        //         ) : (
        //             <div className="flex gap-2">
        //                 <Button
        //                     onClick={() => onAction?.('Edit', record)}
        //                     icon={<EditOutlined />}
        //                     type="primary"
        //                 ></Button>
        //                 <Button
        //                     icon={<DeleteOutlined />}
        //                     type="primary"
        //                     danger
        //                     onClick={() => onAction?.('Delete', record)}
        //                 ></Button>
        //             </div>
        //         );
        //     }
        // }
    ];

    return columnConfig as any
}
