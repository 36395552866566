import { QUERY_KEYS } from 'constants-es';

import { useQuery } from '@tanstack/react-query';

import ChangeCase from 'utils/change-case';

import getMockupDetail from './fetch';
import { MockupDetailRequest } from './interfaces';

export type TUseGetMockupListing = MockupDetailRequest & { enabled?: boolean }

export function useQueryGetMockupDetail({ enabled = true, ...rest }: TUseGetMockupListing) {
    return useQuery({
        queryKey: [QUERY_KEYS.MOCKUP_DETAIL, rest],
        queryFn: () => getMockupDetail(rest),
        select: (res) => {
            return {
                ...res,
                data: ChangeCase.object.toCamelCase(res.data)
            }
        },
        enabled
    });
}

export * from './interfaces';

