import { MUTATION_KEYS } from 'constants-es'

import { useMutation } from '@tanstack/react-query'

import updateFlagError from './fetch'
import { UpdateFlagErrorParams } from './interfaces'

export function useMutationUpdateFlagError() {
  return useMutation({
    mutationFn: (params: UpdateFlagErrorParams)=> updateFlagError(params),
    mutationKey: [MUTATION_KEYS.UPDATE_FLAG_ERROR]
  })
}

export * from './interfaces'
