import { useMutation } from '@tanstack/react-query'

import updateUser from './fetch'

export function useMutationUpdateUser() {
  return useMutation({
    mutationFn: updateUser,
  })
}

export * from './interfaces'
