import { FetcherResponse } from 'interfaces/fetcher';
import axiosInstance from 'utils/fetcher';

import { UserDetailRequest, UserDetailResponse } from './interfaces';

const getUserDetail = async ({id , ...params}: UserDetailRequest): Promise<FetcherResponse<UserDetailResponse>> => {
    const { data: responseData } = await axiosInstance.get<FetcherResponse<UserDetailResponse>>(
        '/rest/v1/user/' + id ,
        {
            params
        }
    );

    return responseData;
};

export default getUserDetail;
