
import { Switch } from 'antd'

import { ImportRequestResponse } from 'modules/import-request/data/queries'

type Props = {
    record?: ImportRequestResponse
}

const ColumnPublish = ({ record }: Props) => {
    return (
        <div>
            <Switch
                unCheckedChildren='Publish'
                checkedChildren='Publish'
                checked={!!record?.defaultPublish}
            />
        </div>
    )
}

export default ColumnPublish