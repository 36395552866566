import { QUERY_KEYS } from 'constants-es';

import { useQuery } from '@tanstack/react-query';

import ChangeCase from 'utils/change-case';

import getAdvanceImgList from './fetch';
import { AdvanceImgListRequest } from './interfaces';

export type TUseGetAdvanceImgList = AdvanceImgListRequest & { enabled?: boolean }

export function useQueryAdvanceImgList({ enabled = true, ...rest }: TUseGetAdvanceImgList = {}) {
    return useQuery({
        queryKey: [QUERY_KEYS.ADVANCE_IMG_LIST, rest],
        queryFn: () => getAdvanceImgList(rest),
        select: (res) => {
            return {
                ...res,
                data: ChangeCase.object.toCamelCase(res.data)
            }
        },
        enabled
    });
}

export * from './interfaces';

