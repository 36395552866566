
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Col, Row, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { isEmpty } from 'lodash';

import { MockupListingResponse } from 'modules/mockup-detail/data/queries';
import CustomTag from 'components/ui/tag';

import ColumnPublish from './components/column-public';

interface GetTableColumnsConfigProps {
    isSmallScreen: boolean;
    onAction?: (key: 'Delete' | 'Edit', record: any) => void;
}

export const getTableColumnsConfig = ({ isSmallScreen, onAction }: GetTableColumnsConfigProps) => {
    const renderIdColumn = (value:number, status:any, request_status_url:any) => {
        if (status === 'Sync') {
            return (
                <a href={request_status_url} target="_blank" style={{ color: 'blue' }}>
                    {value}
                </a>
            );
        } else {
            return value;
        }
    };
    const columnConfig: ColumnsType<MockupListingResponse> = [
        {
            title: <div className='text-center'>ID</div>,
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 100,
            render: (value: MockupListingResponse['id'], record: MockupListingResponse) => {
                return renderIdColumn(value, record.status, record.requestStatusUrl);
            }
        },
        {
            title: <div className='text-center'>Input Image</div>,
            dataIndex: 'inputUrl',
            key: 'input_url',
            align: 'left',
            width: 120,
            render: (value: MockupListingResponse['inputUrl']) => {
                if (!value) return null

                return <LazyLoadImage
                    src={value}
                    alt='Image'
                    width={84}
                    height={84}
                    effect='blur'
                />
            }
        },
        {
            title: <div className='text-center'>Design Image</div>,
            dataIndex: 'designUrl',
            key: 'design_url',
            align: 'left',
            width: 120,
            render: (value: MockupListingResponse['designUrl']) => {
                if (!value) return null

                return <LazyLoadImage
                    src={value}
                    alt='Image'
                    width={84}
                    height={84}
                    effect='blur'
                />
            }
        },
        {
            title: <div className='text-center'>Mockups</div>,
            dataIndex: 'mockupProduct',
            key: 'mockup_product',
            align: 'left',
            render: (value: MockupListingResponse['mockupProduct']) => {
                if (isEmpty(value)) return null

                return <Row gutter={[8, 8]}>
                    {value?.map((item, index) => {
                        return <Col span={6} key={index}>
                            <LazyLoadImage
                                src={item}
                                alt='Image'
                                width={84}
                                height={84}
                                effect='blur'
                            />
                        </Col>
                    })}
                </Row>
            }
        },
        {
            title: <div className='text-center'>Product Name</div>,
            dataIndex: 'productName',
            key: 'product_name',
            align: 'left',
            width: 200,
            render: (value: MockupListingResponse['productName']) => {
                if (!value) return null

                return <div>{value}</div>
            }
        },
        {
            title: <div className='text-center'>Product Type</div>,
            dataIndex: 'productType',
            key: 'product_name',
            align: 'center',
            width: 150,
            render: (value: MockupListingResponse['productType']) => {
                if (!value) return null

                return <div>{value}</div>
            }
        },
        {
            title: <div className='text-center'>Template Type</div>,
            dataIndex: 'templateType',
            key: 'template_type',
            align: 'center',
            width: 150,
            render: (value: MockupListingResponse['templateType']) => {
                if (!value) return null

                return <div>{value}</div>
            }
        },
        {
            title: <div className='text-center'>Status</div>,
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: 100,
            render: (value: MockupListingResponse['status']) => {
                if (!value) return null

                return <CustomTag
                    className='px-3 py-[2px] font-semibold text-[#454954] 
                rounded-xl cursor-pointer'
                >
                    {value}
                </CustomTag>
            }
        },
        {
            title: <div className='text-center'>Public</div>,
            dataIndex: 'templateType',
            key: 'template_type',
            align: 'center',
            width: 150,
            render: (_value: MockupListingResponse['templateType'], record: MockupListingResponse) => {

                return <ColumnPublish record={record} />
            }
        },
        // {
        //     title: 'Actions',
        //     dataIndex: 'actions',
        //     key: 'actions',
        //     width: 100,

        //     render: (_: any, record: any) => {
        //         return isSmallScreen ? (
        //             <Popover
        //                 content={
        //                     <div className="flex gap-2">
        //                         <Button
        //                             onClick={() => onAction?.('Edit', record)}
        //                             icon={<EditOutlined />}
        //                             type="primary"
        //                         ></Button>
        //                         <Button
        //                             icon={<DeleteOutlined />}
        //                             type="primary"
        //                             danger
        //                             onClick={() => onAction?.('Delete', record)}
        //                         ></Button>
        //                     </div>
        //                 }
        //                 placement="bottom"
        //                 trigger="click"
        //             >
        //                 <Button
        //                     type="default"
        //                     icon={
        //                         <BsThreeDots
        //                             style={{
        //                                 verticalAlign: 'middle'
        //                             }}
        //                         />
        //                     }
        //                 ></Button>
        //             </Popover>
        //         ) : (
        //             <div className="flex gap-2">
        //                 <Button
        //                     onClick={() => onAction?.('Edit', record)}
        //                     icon={<EditOutlined />}
        //                     type="primary"
        //                 ></Button>
        //                 <Button
        //                     icon={<DeleteOutlined />}
        //                     type="primary"
        //                     danger
        //                     onClick={() => onAction?.('Delete', record)}
        //                 ></Button>
        //             </div>
        //         );
        //     }
        // }
    ];

    return columnConfig;
};
