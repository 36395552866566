import { AxiosResponse } from 'axios'

import { FetcherResponse } from 'interfaces/fetcher'
import axiosInstance from 'utils/fetcher'

import { CreateUserBody, CreateUserResponseData } from './interfaces'

const createUser = async (
  body: CreateUserBody,
): Promise<FetcherResponse<CreateUserResponseData>> => {
  const  {data: responseData} = await axiosInstance.post<FetcherResponse<CreateUserResponseData>>('/rest/v1/users', {
    ...body
  })


  return responseData
}

export default createUser
