import axios from 'axios';

import { FetcherResponse } from 'interfaces/fetcher';
import axiosInstance from 'utils/fetcher';

import { CreateMockupParams, CreateMockupResponse } from './interfaces';

const createRequestMockup = async (params?: CreateMockupParams): Promise<FetcherResponse<CreateMockupResponse[]>> => {
    const { data: responseData } = await axiosInstance.post<FetcherResponse<CreateMockupResponse[]>>(
        '/api/v1/mockup-design/insert',
        { data: params }
    );

    return responseData;
};

export default createRequestMockup;
