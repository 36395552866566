
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useQueryGetTokenMs } from 'modules/auth/data/queries/use-query-get-token-ms';

import './styles.css'

const WaitingAuthMsChecking = () => {
    const [searchParams] = useSearchParams()
    const code = searchParams.get('code') || ''

    const fetchGetTokenMs = useQueryGetTokenMs({ code })

    useEffect(() => {
        if (!!fetchGetTokenMs?.data) {
            window.opener.postMessage(fetchGetTokenMs?.data, window.location.origin)
            window.close()
        }
    }, [fetchGetTokenMs?.data])

    return (
        <div className='container-loader'>
            <div className="custom-loader"></div>
        </div>
    )
}

export default WaitingAuthMsChecking