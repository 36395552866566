import { useCallback, useEffect, useMemo } from 'react';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { Col, Divider, Row, Table, TablePaginationConfig } from 'antd';
import { identity, pickBy } from 'lodash';

import { useUIConfig } from 'data/store/ui-config/use-ui-config';
import ImportGoogleSheet from 'modules/create/components/prompt-bar/modal-import-gg-sheet';
import { useQueryGetImportRequest } from 'modules/import-request/data/queries';
import { PageHeaderProvider } from 'components/core/page-header-provider';

import ModalCreateRequest from './components/modal-create-request';
import { getTableColumnsConfig } from './table-config';

import 'reactflow/dist/style.css';

const ImportRequest = () => {
    const { isSmallScreen } = useUIConfig();

    const location = useLocation()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const query = useMemo(() => {
        const paramsObj = Object.fromEntries(searchParams.entries());

        return {
            page: searchParams.get('page') ? parseInt(searchParams.get('page') as string) : 1,
            pageSize: searchParams.get('pageSize') ? parseInt(searchParams.get('pageSize') as string) : 10,
            ...paramsObj
        }
    }, [searchParams])

    const updateQuery = useCallback(
        (updateData: Partial<any>) => {

            const newCarrierQuery: any = pickBy(
                {
                    ...query,
                    ...updateData
                },
                identity()
            )
            navigate({
                pathname: location.pathname,
                search: `?${createSearchParams(newCarrierQuery)}`,
            });
        },
        [location.pathname, navigate, query],
    )

    const { data, isFetching } = useQueryGetImportRequest({ enabled: true, ...query });

    const handleOnChangePagination = (pagination: TablePaginationConfig) => {
        const { current, pageSize } = pagination
        const newQuery = {
            page: current,
            pageSize
        } as any

        navigate({
            pathname: location.pathname,
            search: `?${createSearchParams(newQuery)}`,
        });
        updateQuery(newQuery)
    }

    const columns = getTableColumnsConfig({
        isSmallScreen
    });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Row gutter={[16, 16]} style={{ margin: 0 }}>
            <Col span={24}>
                <PageHeaderProvider
                    title='Import Request'
                    headerTitle='Import Request'
                />
            </Col>
            <Col span={24}>
                <div className='fixed top-0 z-10 bg-white w-full left-[256px]'>
                    <div className='flex items-center h-[3.75rem] pl-3'>
                        <h3 className='text-2xl font-semibold mb-0'>
                            Import Request
                        </h3>
                    </div>
                    <Divider className='my-1' />
                </div>
            </Col>
            <Col span={24} className='text-end'>
                <span>
                    <ImportGoogleSheet />
                </span>
                <span className='ms-2'>
                    <ModalCreateRequest />
                </span>
            </Col>
            <Col span={24}>
                <div>
                    <Table
                        rowKey="id"
                        loading={isFetching}
                        dataSource={data?.data || []}
                        columns={columns}
                        pagination={{
                            total: data?.pagination?.total,
                            pageSize: data?.pagination?.pageSize,
                            current: data?.pagination?.page,
                            position: ['topRight', 'bottomRight'],
                            showSizeChanger: true,
                            showQuickJumper: true
                        }}
                        onChange={handleOnChangePagination}
                    />
                </div>
            </Col>
        </Row>
    );
};

export default ImportRequest;
