import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { QUERY_KEYS } from 'constants-es';

import { useQueryClient } from '@tanstack/react-query';
import { Card, Col, Flex, Image, message, Popover, Row, Spin, Tag, Tooltip } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { AiOutlineClose, AiOutlineCopy } from 'react-icons/ai';
import { FaSliders } from "react-icons/fa6";
import { HiPlus } from "react-icons/hi";
import { LuImagePlus } from 'react-icons/lu';

import { useMutationUploadImgAws } from 'modules/create/data/queries';
import { SubmitMJParams, useMutationSubmitMJ } from 'modules/create/data/queries/use-mutation-submit-mj';
import CustomTag from 'components/ui/tag';

import ImportGoogleSheet from './modal-import-gg-sheet';

import './styles.css';

type Props = {
    showGoBack?: boolean
}

export const defaultPrompt = 'Please describe the design with the following structure: [Subject] + [Environment] + [Composition of Image] + [Lighting] + [Camera], but without separate annotations, so that the descriptions are seamlessly connected.'

const PromptBar = (props: Props) => {
    const { showGoBack = false } = props
    const queryClient = useQueryClient()
    const textAreaRef = useRef<any>(null)
    const inputFileRef: any = useRef(null)

    const navigate = useNavigate()

    const [imgUrl, setImgUrl] = useState<string>()
    const [fileUpload, setFileUpload] = useState<any>()
    const [valueTextArea, setValueTextArea] = useState('')
    const [openFilter, setOpenFilter] = useState<boolean>(false)
    const [showUploadImg, setShowUploadImg] = useState<boolean>(false)

    const mutationUploadImgAws = useMutationUploadImgAws()
    const mutationSubmitMJ = useMutationSubmitMJ()

    const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        const validText = valueTextArea.trim()
        if (event.key === 'Enter' && !!validText) {
            event.preventDefault();
            handleSendPrompt()
            setValueTextArea('')
            setImgUrl('')
        }
    };

    const handleGoBack = () => {
        navigate(-1)
    }

    const handleSendPrompt = async () => {
        const params: SubmitMJParams = {
            actions: 'insert',
            data: [
                {
                    gpt_prompt: valueTextArea,
                    original_url: imgUrl,
                }
            ]
        }
        const result = await mutationSubmitMJ.mutateAsync(params)
        message.success(result?.message)
        if (result?.success) {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.REQUEST_LIST] })
        }
    }

    const handleChange = (e: any) => {
        setValueTextArea(e.target.value)
    }

    const handleOnChangeFile = async (e: any) => {
        const value = e.target.files[0]
        if (!value) return

        const formData = new FormData()
        formData.append('folder_name', 'eraser-design')
        formData.append('file', value)
        const result = await mutationUploadImgAws.mutateAsync(formData)
        if (result?.status === 'success') {
            setImgUrl(result?.url)
        }
    }

    useEffect(() => {
        if (textAreaRef.current) {
            const newRef = textAreaRef.current?.resizableTextArea?.textArea
            if (newRef) {
                newRef.style.height = 'auto';
                newRef.style.height = newRef.scrollHeight + 'px';
            }
        }
    }, [valueTextArea])

    return (
        <div className='relative'>
            <div className="bg-nav-gradient-light-to-b fixed z-20 ease-out-quart inset-x-0 h-24 dark:opacity-70 dark:bg-nav-gradient-dark-to-b pointer-events-none top-0 left-[256px] w-[2645px]"></div>
            <div className='border-[1px] rounded-lg shadow-[0px_7px_21px_0px_rgba(51,_51,_51,_0.05)]
                         fixed ml-10 top-5 w-[calc(100%-330px)] z-20 bg-white'
            >
                <Popover
                    open={openFilter}
                    placement='bottomRight'
                    content={
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Card size='small'>
                                    <div className='font-medium text-[#454954] mb-1'>Speed:</div>
                                    <Flex gap="4px 0" wrap="wrap">
                                        <CustomTag className='px-3 font-semibold text-[#454954] rounded-xl cursor-pointer'>Relax</CustomTag>
                                        <CustomTag className='px-3 font-semibold text-[#454954] rounded-xl cursor-pointer' color="#2db7f5">Fast</CustomTag>
                                        <CustomTag className='px-3 font-semibold text-[#454954] rounded-xl cursor-pointer'>Turbo</CustomTag>
                                    </Flex>
                                </Card>
                            </Col>
                        </Row>
                    }
                    autoAdjustOverflow={false}
                    getPopupContainer={(triggerNode) => triggerNode?.parentElement as any}
                >
                    <Spin spinning={mutationSubmitMJ.isPending}>
                        <div className='flex items-start relative'>
                            {showGoBack &&
                                <span className="cursor-pointer duration-200 hover:scale-110 active:scale-100 absolute -left-8 top-3" title="Go Back" onClick={handleGoBack}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" className="stroke-[#f2330d]">
                                        <path stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" d="M11 6L5 12M5 12L11 18M5 12H19"></path>
                                    </svg>
                                </span>
                            }
                            <span className='px-3 py-1 mt-[13px] cursor-pointer group/icon' onClick={() => setShowUploadImg(!showUploadImg)}>
                                <HiPlus className={`${showUploadImg ? 'text-[#f2330d]' : ''} group-hover/icon:opacity-80 w-5 h-5`} />
                            </span>
                            <TextArea
                                size="large"
                                placeholder="Imagine..."
                                style={{ fontFamily: 'Inter, sans-serif', resize: 'none' }}
                                className='border-0 py-2 overflow-hidden will-change-auto'
                                ref={textAreaRef}
                                onChange={handleChange}
                                onFocus={() => setValueTextArea(defaultPrompt)}
                                rows={1}
                                value={valueTextArea}
                                onPressEnter={handleKeyPress}
                                styles={{
                                    textarea: {
                                        padding: 14
                                    }
                                }}
                            />
                            <span className='flex items-center gap-4 px-3 py-1 mt-[6px] cursor-pointer group/icon'>
                                <span>
                                    <ImportGoogleSheet />
                                </span>
                                <span onClick={() => setOpenFilter(!openFilter)}>
                                    <FaSliders className='text-[#f2330d] group-hover/icon:opacity-80 w-5 h-5' />
                                </span>
                            </span>
                        </div>
                        <div className={`p-2 ${showUploadImg ? '' : 'hidden'}`}>
                            {imgUrl ?
                                <Image
                                    src={imgUrl}
                                    alt='image'
                                    width={96}
                                    height={96}
                                    wrapperClassName='border-[1px] rounded-lg h-auto w-auto'
                                    preview={{
                                        mask:
                                            <div className='relative w-full h-full'>
                                                <div className='absolute top-1 right-1 flex space-x-2'>
                                                    {/* Nút Xóa */}
                                                    <div
                                                        className='w-5 h-5 bg-[#ccc] rounded-full
                        flex justify-center items-center hover:opacity-80 transition-all cursor-pointer'
                                                        onClick={() => {
                                                            setImgUrl('');
                                                            setFileUpload(null);
                                                        }}
                                                    >
                                                        <Tooltip title='Remove'>
                                                            <AiOutlineClose className='text-[#504f4f]' size={16}/>
                                                        </Tooltip>
                                                    </div>
                                                    {/* Nút Sao chép */}
                                                    <div
                                                        className='w-5 h-5 bg-[#ccc] rounded-full
                        flex justify-center items-center hover:opacity-80 transition-all cursor-pointer'
                                                        onClick={() => {
                                                            // Copy logic here
                                                            navigator.clipboard.writeText(imgUrl);
                                                            // You can add a feedback message here if needed
                                                        }}
                                                    >
                                                        <Tooltip title='Copy Link'>
                                                            <AiOutlineCopy className='text-[#504f4f]' size={16}/>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </div>,
                                        visible: false,
                                        maskClassName: 'rounded-lg bg-mask-overlay',
                                    }}
                                    className='rounded-lg'
                                />
                                :
                                <div
                                    className='w-24 h-24 rounded-lg flex justify-center 
                                    items-center text-center font-normal bg-[#f1f2f4] text-[#8f94a3]'
                                >
                                    Upload a image
                                </div>
                            }
                            <div className='mt-2'>
                                <div
                                    className='border-[1px] border-solid border-[#ccc]
                                    px-2 py-1 mr-0 mb-4 ml-0 transition-all w-40 cursor-pointer group/icon hover:border-primary mt-1'
                                    onClick={() => mutationUploadImgAws.isPending ? null : inputFileRef.current.click()}
                                >
                                    <Spin spinning={mutationUploadImgAws.isPending}>
                                        <div className='flex justify-center items-center'>
                                            <span>
                                                <LuImagePlus
                                                    className='transition-all group-hover/icon:text-primary mt-[2px]'/>
                                            </span>
                                            <span className='ms-2 group-hover/icon:text-primary transition-all'>
                                                Choose a image
                                            </span>
                                        </div>
                                    </Spin>
                                </div>
                                <input style={{ display: 'none' }} type='file' onChange={handleOnChangeFile} ref={inputFileRef} />
                            </div>
                        </div>
                    </Spin>
                </Popover>
            </div>
        </div>
    )
}

export default PromptBar