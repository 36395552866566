import { ROUTE_PATHS, SidebarMenuItemType } from 'constants-es';

import { TbListDetails } from "react-icons/tb";

import { SidebarMenuItem } from 'interfaces/sidebar-menu-item';

export const mockupDetailSidebarConfig: SidebarMenuItem[] = [
    {
        id: 'mockup-detail',
        label: 'Mockup Detail',
        path: ROUTE_PATHS.MOCKUP_DETAIL_LIST,
        type: SidebarMenuItemType.Item,
        icon: <TbListDetails />,
        permissions: [],
        children: []
    }
];
