import { Tag } from 'antd'

export const tailwindTagStatus = `px-3 py-[2px] font-semibold text-[#454954] 
rounded-xl cursor-pointer`

type Props = {
    value?: string
    children?: React.ReactNode
    className?: string
    color?: string
    [x: string]: any
}

const CustomTag = (props: Props) => {
    const { value, children, className, ...rest } = props

    return (
        <Tag
            className={className}
            {...rest}
        >
            {value || children}
        </Tag>
    )
}

export default CustomTag