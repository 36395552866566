import { useMutation } from '@tanstack/react-query'

import submitMJ from './fetch'
import { SubmitMJParams } from './interfaces'

export function useMutationSubmitMJ() {
  return useMutation({
    mutationFn: (params :SubmitMJParams)=> submitMJ(params),
  })
}

export * from './interfaces'
