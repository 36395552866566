import { FetcherResponse } from 'interfaces/fetcher'
import axiosInstance from 'utils/fetcher'
import { RequestListResponseData } from 'modules/create/pages/create-listing/data/queries'

import { UpdateFlagErrorParams } from './interfaces'

const updateFlagError = async (
  params: UpdateFlagErrorParams,
): Promise<FetcherResponse<RequestListResponseData[]>> => {
  const  {data: responseData} = await axiosInstance.put<FetcherResponse<RequestListResponseData[]>>(`/api/v1/mockup-design/design/flag`, params)


  return responseData
}

export default updateFlagError
