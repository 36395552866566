import { FetcherResponse } from 'interfaces/fetcher'
import axiosInstance from 'utils/fetcher'

const getUrlMsLogin = async (): Promise<FetcherResponse<any>> => {
  const responseData =  await axiosInstance.get('/api/v1/signin-ms')

  return responseData?.data
}

export default getUrlMsLogin
