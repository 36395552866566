import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'libs/redux'

import { ScrollPositionState, SetScrollPositionPayload } from './interface'
import { setScrollPosition } from './slice'

interface UseScrollPosition extends ScrollPositionState {
  setScrollPosition: (payload: SetScrollPositionPayload) => void
}

export const useScrollPosition = (): UseScrollPosition => {
  const scrollPosition = useAppSelector((state) => state.scrollPosition)
  const dispatch = useAppDispatch()

  const handleSetScrollPosition = useCallback(
    (payload: SetScrollPositionPayload) => {
      dispatch(setScrollPosition(payload))
    },
    [dispatch],
  )

  return {
    ...scrollPosition,
    setScrollPosition: handleSetScrollPosition,
  }
}
