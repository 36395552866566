import { FetcherResponse } from 'interfaces/fetcher'
import axiosInstance from 'utils/fetcher'

export type TCreateGetTokenMsLogin = {
  code?: string
}

const createGetTokenMsLogin = async (params: TCreateGetTokenMsLogin): Promise<FetcherResponse<any>> => {
  const responseData =  await axiosInstance.post('/api/v1/signin-ms/callback', params)

  return responseData?.data
}

export default createGetTokenMsLogin
