export const handleTransformValueSelect: any = (values: any, key = 'value') => {
    if (typeof values === 'boolean') return values;
    if (values === undefined || values === null) return null;
    if (Array.isArray(values)) {
        return values.map((item) => {
            return handleTransformValueSelect(item);
        });
    }

    if (typeof values === 'object') {
        return !!values?.[key] || values?.[key] === 0 ? values[key] : values;
    }

    return values;
};

export const handleTransformGroupValuesSelect = (values: any) => {
    if (typeof values === 'object') {
        const temArr = Object.entries(values || {}).map(([key, value]) => {
            return { key, value };
        });

        const newArr = temArr.map((item) => {
            return {
                key: item.key,
                value: handleTransformValueSelect(item.value)
            };
        });

        const newObj: any = {};
        newArr?.forEach((item) => {
            if (!item?.key) return;
            newObj[item?.key] = item?.value;
        });

        return newObj;
    }

    return values;
};
