import { authSlice,pageMetadataSlice, reactFlowSlice,uiConfigSlice  } from 'data/store'
import { modalSlice } from 'data/store/modal'
import { responseMarkErrorSlice } from 'data/store/response-mark-error'
import { scrollPositionSlice } from 'data/store/scroll-position'


export const reducer = {
  [uiConfigSlice.name]: uiConfigSlice.reducer,
  [pageMetadataSlice.name]: pageMetadataSlice.reducer,
  [reactFlowSlice.name]: reactFlowSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [scrollPositionSlice.name]: scrollPositionSlice.reducer,
  [responseMarkErrorSlice.name]: responseMarkErrorSlice.reducer,
  [modalSlice.name]: modalSlice.reducer,
}
