
import { useState } from 'react';

import { Button, Card, Col, Row, Table } from 'antd';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';

import { useUIConfig } from 'data/store/ui-config/use-ui-config';
import { useQueryGetMockupDetail } from 'modules/mockup-detail/data/queries/use-query-mockup-detail';
import { getTableColumnsConfig } from 'modules/mockup-detail/pages/mockup-listing/table-config';

import { RequestListResponseData } from '../../../../data/queries';

type Props = {
    item?: RequestListResponseData
}

const TableDetail = (props: Props) => {
    const { item } = props;
    const { isSmallScreen } = useUIConfig();

    const [openTableDetail, setOpenTableDetail] = useState<boolean>(false)

    const { data, isFetching } = useQueryGetMockupDetail({ enabled: true, mj_id: item?.idMj });

    const columns = getTableColumnsConfig({
        isSmallScreen
    });

    return (
        <Row gutter={[16, 16]} style={{ margin: 0 }}>
            <Col span={24}>
                <div className='text-end'>
                    <Button
                        size='small'
                        type="primary"
                        ghost
                        onClick={() => setOpenTableDetail(!openTableDetail)}
                        icon={openTableDetail ? <IoChevronUp className='me-0' /> : <IoChevronDown className='me-0' />}
                    >
                        Detail
                    </Button>
                </div>
            </Col>
            {openTableDetail &&
                <Col span={24}>
                    <Card size='small'>
                        <Table
                            rowKey='id'
                            loading={isFetching}
                            dataSource={data?.data || []} columns={columns}
                            size='small'
                            pagination={{
                                pageSize: 2,
                                total: 4 || 0,
                                showSizeChanger: false,
                            }}
                        />
                    </Card>
                </Col>
            }
        </Row>
    )
}

export default TableDetail