import { ROUTE_PATHS, SidebarMenuItemType } from 'constants-es';

import { LuPaintbrush2 } from 'react-icons/lu';

import { SidebarMenuItem } from 'interfaces/sidebar-menu-item';

export const createSidebarConfig: SidebarMenuItem[] = [
    {
        id: 'create',
        label: 'Create',
        path: ROUTE_PATHS.CREATE_IMAGINE,
        type: SidebarMenuItemType.Item,
        icon: <LuPaintbrush2 />,
        permissions: [],
        children: []
    }
];
