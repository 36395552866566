import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from 'libs/redux';
import { cx } from 'utils';

import { MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Divider, FloatButton, Layout, theme } from 'antd';
import { Footer } from 'antd/es/layout/layout';
import { AiOutlineMail } from 'react-icons/ai';
import { VscSignOut } from 'react-icons/vsc';
import clsx from 'clsx';
import { isEmpty } from 'lodash';

import { getUser, removeAuth } from 'helpers/auth';
import { resetAuth, setAuthUser } from 'data/store';
import { useUIConfig } from 'data/store/ui-config/use-ui-config';
import { useMutationLogout } from 'modules/auth/data/queries';
import { useQueryUserProfile } from 'modules/auth/data/queries/use-query-user-profile';
import { sidebarConfigs } from 'modules/sidebar-config';
import ValidateScreen from 'components/HOC/validate-screen';
import Logout from 'components/ui/logout-btn';

import PageHeader from '../page-header';
import SidebarMenu from '../sidebar-menu';

const { Header, Sider, Content } = Layout;

type Props = {
    children: React.ReactNode | React.ReactNode[];
};

const PrivateLayout = ({ children }: Props) => {
    const { isSmallScreen } = useUIConfig();
    const [collapsed, setCollapsed] = useState(isSmallScreen);
    const dispatch = useAppDispatch()
    const user = getUser()

    const mutationLogout = useMutationLogout();
    const handleLogout = useCallback(() => {
        // if (isAdminRoute) {

        // mutationLogout.mutate(undefined, { 
        //     onSuccess: (response: any) => {
        //         removeAuth();
        //         dispatch(resetAuth());
        //     },
        //     onError: (error: any) => {
        //         message.error(error.message);
        //     }
        // });
        removeAuth();
        dispatch(resetAuth());
    }, [dispatch, mutationLogout]);

    const {
        token: { colorBgContainer, borderRadiusLG }
    } = theme.useToken();

    return (
        <ValidateScreen>
            <Layout>
                <Sider
                    width={256}
                    collapsed={collapsed}
                    onCollapse={(value) => setCollapsed(value)}
                    style={{
                        // overflow: 'auto',
                        height: 'calc(100vh)',
                        position: 'fixed',
                        left: 0,
                        top: 0,
                        bottom: 0,
                        background: colorBgContainer
                    }}
                    className="shadow-lg"
                >
                    <div className="w-full text-center">
                        <img
                            src={collapsed ? '/logo200x200.png' : '/logo2000x400.png'}
                            alt={collapsed ? 'logo200x200.png' : 'logo2000x400.png'}
                            className={cx('w-16 h-16 object-contain', {
                                '!w-[200px] !h-[64px] ': !collapsed
                            })}
                        />
                    </div>
                    <Divider className="!my-0 border-[#f1f1f1]" />
                    <div className="w-full flex justify-center flex-col items-center py-3 px-6 border-[#f1f1f1]">
                        <Avatar size={40} icon={<UserOutlined />} />
                        <div className='flex gap-1 items-center mt-1'>
                            <span>
                                <AiOutlineMail style={{ verticalAlign: 'middle' }} />
                            </span>
                            <span>
                                {user?.email}
                            </span>
                        </div>
                    </div>
                    <Divider className="!my-0 border-[#f1f1f1]" orientation="right" />
                    <SidebarMenu sidebarConfig={sidebarConfigs} userPermissions={[]} />
                    <div onClick={handleLogout} className='flex justify-center items-center h-10'>
                        <Logout />
                    </div>
                </Sider>
                <Layout
                    className={cx('transition-all', {
                        'ml-[256px]': !collapsed,
                        'ml-[80px]': collapsed
                    })}
                >
                    {/* <Header
                        style={{ padding: 0, background: colorBgContainer }}
                        className="flex items-center justify-center w-full bg-white border-b border-gray-300"
                    >
                        <PageHeader sidebarConfig={sidebarConfigs} />
                    </Header> */}
                    <Content
                        style={{
                            // margin: '0 16px',
                            // padding: '16px 16px 0 16px',
                            marginTop: 64,
                            minHeight: 'calc(100vh - 72px )',
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG
                        }}
                    >
                        {children}
                        {/* <FloatButton.BackTop /> */}
                    </Content>
                </Layout>
            </Layout>
        </ValidateScreen>
    );
};

export default PrivateLayout;
