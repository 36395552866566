import { FetcherResponse } from 'interfaces/fetcher';
import axiosInstance from 'utils/fetcher';

import { MockupListingResponse } from '../use-query-mockup-listing';
import { MockupDetailRequest } from './interfaces';

const getMockupDetail = async (params: MockupDetailRequest): Promise<FetcherResponse<MockupListingResponse[]>> => {
    const { data: responseData } = await axiosInstance.get<FetcherResponse<MockupListingResponse[]>>(
        '/api/v1/mockup-design/get-detail',
        {
            params
        }
    );

    return responseData;
};

export default getMockupDetail;
