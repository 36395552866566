import axios from 'axios'

import { UploadImgAwsResponseData } from './interfaces'

const uploadImgAws = async (
  params: FormData,
): Promise<UploadImgAwsResponseData> => {
  const newFormData = params
  const folder_name = newFormData.get('folder_name')
  const  {data: responseData} = await axios.post<UploadImgAwsResponseData>(`https://service.canawan.com/pts-api/aws/folders/${folder_name}/upload/`, params, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })


  return responseData
}

export default uploadImgAws
