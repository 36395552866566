import { FetcherResponse } from 'interfaces/fetcher';
import axiosInstance from 'utils/fetcher';

import { UserResponse } from './interfaces';

const getUsers = async (): Promise<FetcherResponse<UserResponse[]>> => {
    const { data: responseData } = await axiosInstance.get<FetcherResponse<UserResponse[]>>('/rest/v1/users');

    return responseData;
};

export default getUsers;
