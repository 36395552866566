import { useMutation } from '@tanstack/react-query'

import importGoogleSheet from './fetch'
import { ImportGoogleSheetParams } from './interfaces'

export function useMutationImportGoogleSheet() {
  return useMutation({
    mutationFn: (params :ImportGoogleSheetParams)=> importGoogleSheet(params),
  })
}

export * from './interfaces'
