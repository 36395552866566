import { QUERY_KEYS } from 'constants-es';

import { useQuery } from '@tanstack/react-query';

import ChangeCase from 'utils/change-case';

import getMockupListing from './fetch';
import { MockupListingRequest } from './interfaces';

export type TUseGetMockupListing = MockupListingRequest & { enabled?: boolean }

export function useQueryGetMockupListing({ enabled = true, ...rest }: TUseGetMockupListing) {

    return useQuery({
        queryKey: [QUERY_KEYS.MOCKUP_LISTING, rest],
        queryFn: () => getMockupListing(rest),
        select: (res) => {
            return {
                ...res,
                data: ChangeCase.object.toCamelCase(res.data)
            }
        },
        placeholderData: (previousData, _previousQuery) => previousData,
        enabled,
    });
}

export * from './interfaces';

