
import { FetcherResponse } from 'interfaces/fetcher';
import axiosInstance from 'utils/fetcher';

import { MockupListingRequest, MockupListingResponse } from './interfaces';

const getMockupListing = async (params: MockupListingRequest): Promise<FetcherResponse<MockupListingResponse[]>> => {
    const { data: responseData } = await axiosInstance.get<FetcherResponse<MockupListingResponse[]>>(
        '/api/v1/mockup-design/list-request',
        {
            params
        }
    );

    return responseData;
};

export default getMockupListing;
