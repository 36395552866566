export const ROUTE_PATHS = {
  ADMIN_HOME: '/admin',
  CLIENT_HOME: '/client',
  SIGN_UP: '/sign-up',
  SIGN_IN: '/sign-in',
  FORGOT_PASSWORD: '/forgot-password',
  RECOVERY_PASSWORD: '/recovery-password',

  //Users
  USERS: '/users',
  USER_DETAIL: '/user-detail',

  //Create Imagine
  CREATE_IMAGINE: '/imagine',
  DETAIL_IMAGINE:'/detail-mockup',

  //Mockup Detail
  MOCKUP_DETAIL_LIST: '/mockup-detail',
  MOCKUP_DETAIL_DETAIL:'/mockup-detail/:id',

  //Waiting Authentication MS
  // WAITING_AUTH_MS: '/auth/microsoft/callback',
  WAITING_AUTH_MS: '/rest/oauth2-credential/callback',

  //Import Request
  IMPORT_REQUEST_LIST: '/import-request',
}
