import { FetcherResponse } from 'interfaces/fetcher'
import axiosInstance from 'utils/fetcher'

import { SubmitMJParams, SubmitMJResponseData } from './interfaces'

const submitMJ = async (
  params: SubmitMJParams,
): Promise<FetcherResponse<SubmitMJResponseData>> => {
  const  {data: responseData} = await axiosInstance.post<FetcherResponse<SubmitMJResponseData>>('/api/v1/mockup-design-request/insert', params)


  return responseData
}

export default submitMJ
