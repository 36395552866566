import { FetcherResponse } from 'interfaces/fetcher';
import axiosInstance from 'utils/fetcher';

import { AdvanceImgListRequest, AdvanceImgListResponse } from './interfaces';

const getAdvanceImgList = async (params: AdvanceImgListRequest): Promise<FetcherResponse<AdvanceImgListResponse[]>> => {
    const { mj_id, ...rest } = params;
    const { data: responseData } = await axiosInstance.get<FetcherResponse<AdvanceImgListResponse[]>>(
        `/api/v1/mockup-design/${mj_id}/child`,
        {
            params: rest
        }
    );

    return responseData;
};

export default getAdvanceImgList;
