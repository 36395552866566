
import { FetcherResponse } from 'interfaces/fetcher'
import axiosInstance from 'utils/fetcher'

import { UpdateUserParams, UpdateUserResponseData } from './interfaces'

const updateUser = async (
  params: UpdateUserParams,
): Promise<FetcherResponse<UpdateUserResponseData>> => {
  const  {data: responseData} = await axiosInstance.patch<FetcherResponse<UpdateUserResponseData>>('/rest/v1/flows?id=eq.'+params.id, params)


  return responseData
}

export default updateUser
