import { LazyLoadImage } from 'react-lazy-load-image-component'

import { Checkbox } from 'antd'

import { ImageItem } from 'modules/create/pages/create-listing/data/queries'

type Props = {
    shadow?: string
    onCheck?: (id: any) => void
    imageItem?: ImageItem
    minHeight?: any
}

const ImageItemChecked = (props: Props) => {
    const { shadow, onCheck, imageItem, minHeight = 290 } = props

    return (
        <div
            className={`relative cursor-pointer ${shadow} transition-all rounded-xl`}
            style={{ minHeight: minHeight }}
        >
            <LazyLoadImage
                src={imageItem?.storageUrl}
                className='rounded-xl w-full h-auto'
                onClick={() => onCheck?.(imageItem?.idStorage)}
                style={{ minHeight: minHeight }}
                threshold={4000}
                loading='lazy'
                data-fancybox="gallery"
            />
            <div className='absolute bottom-0 right-0 bg-transparent'>
                <Checkbox
                    value={imageItem?.idStorage}
                    style={{ padding: '20px 20px 20px 35px' }}
                />
            </div>
        </div>
    )
}

export default ImageItemChecked