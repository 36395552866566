import React from 'react';
import { QUERY_KEYS } from 'constants-es';

import { useQueryClient } from '@tanstack/react-query';
import { Form, Input, message, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { isEmpty } from 'lodash';

import { User } from 'interfaces/user';
import { useMutationCreateUser, useMutationUpdateUser, useQueryGetUserDetail } from 'modules/users/data/queries';


type ModalUserProps = {
    open: boolean;
    onCancel: () => void;
    data:User
}
export const ModalUser = ({data , onCancel, open}:ModalUserProps) => {
    const [form] = useForm();
    const queryClient = useQueryClient();

    const mutationCreateUser = useMutationCreateUser();
    const mutationUpdateUser = useMutationUpdateUser();
    const {data:dataDetail , isFetching:loadingDetail}  = useQueryGetUserDetail(data?.id)
    const loading = mutationCreateUser.isPending;

    const handleSubmit = (values: any) => {
        // values => values form
        if(data?.id){
            mutationUpdateUser.mutate({id:data?.id , ...values}, {
                onSuccess: (response: any) => {
                    if (response.success) {
                        message.success('Update successfully');
                        onCancel();
                        queryClient.invalidateQueries({
                            queryKey: [QUERY_KEYS.USERS]
                        });
                    } else {
                        message.error(response.message);
                    }
                }
            });
        }else{
            const dataCreated = { name: values.name};
            mutationCreateUser.mutate(dataCreated, {
                onSuccess: (response: any) => {
                    if (response.success) {
                        message.success('Create successfully');
                        onCancel();
                        queryClient.invalidateQueries({
                            queryKey: [QUERY_KEYS.USERS]
                        });
                    } else {
                        message.error(response.message);
                    }
                }
            });

        }

    };
    const handleCancel = () => {
        onCancel();
    }

    React.useEffect(() => {
        if(!isEmpty(dataDetail) && data?.id){
            form.setFieldsValue(dataDetail)
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.id, dataDetail]);
    
return (
        <>
          
            <Modal
                open={open}
                confirmLoading={loading}
                afterClose={() => form.resetFields()}
                title={ data?.id ? 'Edit User' : 'Create User'}
                destroyOnClose
                onCancel={handleCancel}
                onOk={() => form.submit()}
            >
                <Form form={form} onFinish={handleSubmit} layout="vertical">
                    <Form.Item
                        name={'name'}
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: 'Please input name !'
                            }
                        ]}
                    >
                        <Input placeholder="Name" />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
