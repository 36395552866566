export const convertNormalizeString = (value: string) => {
    if (!value) return value;
    let stringValue = value;
    if (typeof stringValue === 'number') {
        const numberValue = stringValue as number;
        stringValue = numberValue.toString();
    } else if (stringValue === undefined) {
        stringValue = '';
    }

    const newString = stringValue?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '');

    return newString;
};