
import { FetcherResponse } from 'interfaces/fetcher'
import axiosInstance from 'utils/fetcher'

import { RequestRegisterBody, RequestRegisterResponseData } from './interfaces'

const requestRegister = async (
  body: RequestRegisterBody,
): Promise<FetcherResponse<RequestRegisterResponseData>> => {
  const  {data: responseData} = await axiosInstance.post<FetcherResponse<RequestRegisterResponseData>>('/api/v1/auth/register', body)


  return responseData
}

export default requestRegister
