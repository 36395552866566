import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector } from 'react-redux'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { ROUTE_PATHS } from 'constants-es'
import { ReduxState } from 'libs/redux'

import { Button, Card, Checkbox, Col, Divider, Empty, Row, Spin } from 'antd'
import { isArray, isEmpty } from 'lodash'

import { FetcherResponse } from 'interfaces/fetcher'
import { useScrollPosition } from 'data/store/scroll-position/use-scroll-position'

import { RequestListResponseData, useQueryRequestList } from '../../data/queries'
import ItemContent from './item'

const selectorScrollPosition = (state: ReduxState) => state.scrollPosition
const selectorResponseMarkError = (state: ReduxState) => state.responseMarkError

const ContentPage = () => {
    const navigate = useNavigate()
    const { setScrollPosition } = useScrollPosition()
    const { scrollPosition, scrollLength } = useSelector(selectorScrollPosition)
    const { data: responseMarkErrorData } = useSelector(selectorResponseMarkError)

    const [selectedItems, setSelectedItems] = useState<string[]>([])
    const [enabledFetch, setEnabledFetch] = useState<boolean>(true)
    const [requestListData, setRequestListData] = useState<RequestListResponseData[]>([])

    const { data, fetchNextPage, hasNextPage, isLoading } = useQueryRequestList({
        enabled: enabledFetch,
        page: 1,
        pageSize: 50
    })

    const handleClickItem = () => {
        if (isEmpty(selectedItems)) return

        navigate({
            pathname: ROUTE_PATHS.DETAIL_IMAGINE,
            search: createSearchParams({
                id: selectedItems.join(',')
            }).toString()
        })
    }

    const handleSelectAll = (values: any) => {
        setSelectedItems(values)
    }

    const handleScroll = useCallback(() => {
        const position = window.scrollY ? window.scrollY : 0;
        setScrollPosition({ scrollPosition: position, scrollLength: requestListData.length });
    }, [requestListData.length, setScrollPosition])

    useEffect(() => {
        if (requestListData.length > 0) {
            window.addEventListener('scroll', handleScroll)
        }

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [handleScroll, requestListData.length])

    useEffect(() => {
        if (scrollPosition && scrollLength > 0 && scrollLength <= requestListData.length) {
            window.scrollTo(0, scrollPosition);
        }
    }, [scrollPosition, requestListData, scrollLength])

    useEffect(() => {
        if (data?.pages) {
            const tempData2: RequestListResponseData[] = data?.pages.reduce((
                acc: FetcherResponse<RequestListResponseData[]>,
                cur: any) => {
                if (!Array.isArray(acc) || !Array.isArray(cur?.data || [])) return []

                return acc.concat(cur.data)
            }, [])

            const isExistOpen = tempData2?.every((item) => item?.status !== 'Open')

            setEnabledFetch(!isExistOpen)
            setRequestListData(tempData2)
        }
    }, [data?.pages]);

    const newRequestListData = useMemo(() => {
        const responseReverse = isArray(responseMarkErrorData) ? [...responseMarkErrorData].reverse() : []
        const listRemoveDuplicate = responseReverse?.filter((item, index, self) => self.findIndex((t) => t.id === item.id) === index)
        const newResponseMarkErrorData = listRemoveDuplicate?.map((item) => item?.id) || []

        if (newResponseMarkErrorData.length > 0) {
            const tempNewData = requestListData.map((item) => {
                if (newResponseMarkErrorData.includes(item?.id)) {
                    const newItem = responseMarkErrorData[newResponseMarkErrorData.indexOf(item?.id)]

                    return {
                        ...item,
                        designErrorFlag: newItem?.designErrorFlag,
                    }
                }

                return item
            })

            return tempNewData
        }

        return requestListData
    }, [responseMarkErrorData, requestListData])


    return (
        <Spin spinning={isLoading} tip="Loading...">
            {isEmpty(newRequestListData) ?
                <div className='mt-48'>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
                : <Row gutter={[16, 16]} style={{ margin: 0 }}>
                    <InfiniteScroll
                        dataLength={newRequestListData?.length}
                        next={() => {
                            fetchNextPage()
                        }}
                        hasMore={hasNextPage}
                        loader={<></>}
                    >
                        <Col span={24}>
                            <Checkbox.Group onChange={handleSelectAll}>
                                <Row gutter={[16, 16]} className='relative mb-16'>
                                    {newRequestListData?.map((item, index: number) => {
                                        const isLastIndex = index === newRequestListData?.length - 1

                                        return (
                                            <Fragment key={index}>
                                                <Col span={24}>
                                                    <ItemContent
                                                        item={item}
                                                        selectedItems={selectedItems}
                                                    />
                                                </Col>
                                                {!isLastIndex && <Divider className='my-1' />}
                                            </Fragment>
                                        )
                                    })}
                                    <Col
                                        span={24}
                                        className='fixed left-[255px] bottom-0 w-[calc(100%-255px)] border-t-[1px]'
                                    >
                                        <Card size='small' className='relative border-none'>
                                            <div className='flex items-center justify-center'>
                                                <Button type='primary' className='w-64' onClick={handleClickItem}>
                                                    Create Mockup
                                                </Button>
                                            </div>
                                            {/* <Checkbox className='w-40 absolute top-1/2 right-0 -translate-y-1/2'>
                                            Select all
                                        </Checkbox> */}
                                        </Card>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Col>
                    </InfiniteScroll>
                </Row >
            }
        </Spin >
    )
}

export default ContentPage