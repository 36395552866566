import { useMutation } from '@tanstack/react-query'

import CreateUser from './fetch'

export function useMutationCreateUser() {
  return useMutation({
    mutationFn: CreateUser,
  })
}

export * from './interfaces'
