export const QUERY_KEYS = {
  USER_PROFILE: 'USER_PROFILE',
  CHECK_TOKEN_RECOVERY: 'CHECK_TOKEN_RECOVERY',
  WORKFLOWS: 'WORKFLOWS',
  WORKFLOW_DETAIL: 'WORKFLOW_DETAIL',
  USERS : 'USERS',
  USER_DETAIL: 'USER_DETAIL',
  TODOS : 'TODOS',
  TODO_DETAIL: 'TODO_DETAIL',

  //List Request
  REQUEST_LIST: 'REQUEST_LIST',
  REQUEST_DETAIL: 'REQUEST_DETAIL',

  //List Mockup
  MOCKUP_LISTING: 'MOCKUP_LISTING',
  MOCKUP_DETAIL: 'MOCKUP_DETAIL', 

    //Login MS
  GET_URL_LOGIN_MS: 'GET_URL_LOGIN_MS',
  GET_TOKEN_LOGIN_MS: 'GET_TOKEN_LOGIN_MS',

  //Import Request
  IMPORT_REQUEST: 'IMPORT_REQUEST',

  //Advance List
  ADVANCE_IMG_LIST: 'ADVANCE_IMG_LIST',
}

export const MUTATION_KEYS = {
  UPDATE_PUBLISH: 'UPDATE_PUBLISH',
  UPDATE_FLAG_ERROR: 'UPDATE_FLAG_ERROR',
}
