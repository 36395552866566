import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ModalState, SetModalPayload } from './interface'

const initialState: ModalState = {
  open: false,
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModal: (state, action: PayloadAction<SetModalPayload>) => {
      state.open = action.payload.open
    },
  },
})

export const { setModal } = modalSlice.actions
