import { FetcherResponse } from 'interfaces/fetcher'
import axiosInstance from 'utils/fetcher'

import { RequestListParams, RequestListResponseData } from './interfaces'

const getRequestList = async (
  params?: RequestListParams,
): Promise<FetcherResponse<RequestListResponseData[]>> => {
  const  {data: responseData} = await axiosInstance.get<FetcherResponse<RequestListResponseData[]>>(
    '/api/v1/mockup-design/list',
    {
      params
    }
  )
  
  return responseData
}

export default getRequestList
