import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ResponseMarkErrorState, SetResponseMarkErrorStatePayload } from './interface'

const initialState: ResponseMarkErrorState = {
  data: [],
}

export const responseMarkErrorSlice = createSlice({
  name: 'responseMarkError',
  initialState,
  reducers: {
    setResponseMarkError: (state, action: PayloadAction<SetResponseMarkErrorStatePayload>) => {
      const newState = [...state.data,...action.payload.data]
      state.data = newState
    },
  },
})

export const { setResponseMarkError } = responseMarkErrorSlice.actions
