import { Checkbox, Col, Form, FormListFieldData, Row, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'

import { convertNormalizeString } from 'utils/convert-normalize'
import { messageErr } from 'utils/error-message'
import { validateWhiteSpace } from 'utils/validate'
import { RequestDetailResponse } from 'modules/create/pages/create-detail/data/queries'

type Props = {
    item?: RequestDetailResponse
    field: FormListFieldData
}

export const listTemplateType = [
    {
        value: '2 DESIGNS',
        label: '2 DESIGNS'
    },
    {
        value: '2DS BLACK TEMPLATE',
        label: '2DS BLACK TEMPLATE'
    },
    {
        value: '2DS WHITE TEMPLATE',
        label: '2DS WHITE TEMPLATE'
    }
]

export const listProductType = [
    {
        value: '2D Combo Shirt',
        label: '2D Combo Shirt'
    }
]

const FormCreateDetail = (props: Props) => {
    const { field } = props

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Form.Item name={[field.name, "value"]} valuePropName='value' hidden />
                <Form.Item
                    name={[field.name, "productName"]}
                    label="Product Name"
                    rules={[
                        {
                            required: true,
                            message: messageErr.required
                        },
                        () => validateWhiteSpace()
                    ]}
                >
                    <TextArea
                        placeholder='Please enter product name...'
                        rows={3}
                    />
                </Form.Item>
                <Form.Item
                    name={[field.name, "templateType"]}
                    label="Template Type"
                    rules={[
                        {
                            required: true,
                            message: messageErr.required
                        }
                    ]}
                >
                    <Select
                        options={listTemplateType}
                        showSearch
                        filterOption={(input: any, option: any) => {
                            const label = option["label"]
                            const oriString = convertNormalizeString(label)
                            const stringToCompare = convertNormalizeString(input)
                            const result = (`${oriString ?? ''}`?.toLowerCase() ?? '').indexOf(stringToCompare?.toLowerCase()) > -1;

                            return result
                        }}
                        placeholder="Please select template..."
                        allowClear
                    />
                </Form.Item>
                <Form.Item
                    name={[field.name, "productType"]}
                    label="Product Type"
                    rules={[
                        {
                            required: true,
                            message: messageErr.required
                        }
                    ]}
                >
                    <Select
                        options={listProductType}
                        showSearch
                        filterOption={(input: any, option: any) => {
                            const label = option["label"]
                            const oriString = convertNormalizeString(label)
                            const stringToCompare = convertNormalizeString(input)
                            const result = (`${oriString ?? ''}`?.toLowerCase() ?? '').indexOf(stringToCompare?.toLowerCase()) > -1;

                            return result
                        }}
                        placeholder="Please select product type..."
                    />
                </Form.Item>
                <Form.Item
                    name={[field.name, "catalogPublish"]}
                    label="Publish"
                    valuePropName='checked'
                >
                    <Checkbox />
                </Form.Item>
            </Col>
        </Row >

    )
}

export default FormCreateDetail