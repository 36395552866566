import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from 'constants-es';
import { useAppDispatch } from 'libs/redux';

import { Button, Form, Input, message } from 'antd';

import { setAuthUser } from 'data/store';
import { useMutationRequestLogin } from 'modules/auth/data/queries';
import { useMutationGetUrlLoginMs } from 'modules/auth/data/queries/use-mutation-get-url';

export const openPopup = (url: string, width: number, height: number) => {
    const leftPosition = (window.screen.width - width) / 2;
    const topPosition = (window.screen.height - height) / 2;
    const popupOptions = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${topPosition}, left=${leftPosition}`;

    window.open(url, 'popupWindow', popupOptions);
};

const SignIn = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const mutationRequestLogin = useMutationRequestLogin();
    const mutationGetUrlLoginMs = useMutationGetUrlLoginMs();
    const [errorLogin, setErrorLogin] = useState<string>()

    const handleLoginMs = async () => {
        const result = await mutationGetUrlLoginMs.mutateAsync()
        if (!!result?.success) {
            openPopup(result?.data?.url || '', 500, 600)
        }
    }

    const handleFinish = ({ email, password }: any) => {
        mutationRequestLogin.mutate(
            { email, password },
            {
                onSuccess: (response: any) => {
                    if (!!response?.success) {
                        message.success(response?.message);
                        dispatch(
                            setAuthUser({
                                user: response.data?.user,
                                accessToken: response?.data.token
                            })
                        );
                        navigate(ROUTE_PATHS.CREATE_IMAGINE);
                    } else {
                        message.error(response.message);
                    }
                },
                onError: (error: any) => {
                    message.error(error.message);
                }
            }
        );
    };

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.origin === window.location.origin) {
                const { data } = event;
                if (!!data?.success) {
                    message.success(data?.message);
                    dispatch(
                        setAuthUser({
                            user: data?.data?.user,
                            accessToken: data?.data.token
                        })
                    );
                    navigate(ROUTE_PATHS.CREATE_IMAGINE);
                } else if (data?.success === false) {
                    setErrorLogin(data?.message);
                }
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!!errorLogin) {
            message.error(errorLogin);
        }
    }, [errorLogin])

    return (
        <div className="flex items-center justify-center h-screen">
            <div className="flex-col px-6 bg-white border rounded shadow-md min-w-fit pt-14 pb-4">
                <div className="flex justify-center mb-8">
                    <img className="w-24" src="/logo200x200.png" alt="" />
                </div>
                <Form className="flex flex-col text-sm rounded-md" onFinish={handleFinish}>
                    <Form.Item
                        name={'email'}
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: 'Please input your email!'
                            }
                        ]}
                    >
                        <Input size="large" type="Email" placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        name={'password'}
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: 'Please input your password!'
                            }
                        ]}
                    >
                        <Input size="large" type="password" placeholder="Password" />
                    </Form.Item>

                    <Button
                        loading={mutationRequestLogin.isPending}
                        type="primary"
                        className="w-full p-2 mt-5 "
                        htmlType="submit"
                        size="large"
                    >
                        Sign in
                    </Button>
                </Form>
                <div className="flex justify-between mt-5 text-sm text-gray-600">
                    <Link to={ROUTE_PATHS.FORGOT_PASSWORD} className='no-underline'>Forgot password?</Link>
                    <Link to={ROUTE_PATHS.SIGN_UP} className='no-underline font-'>Sign up</Link>
                </div>
                <div className="flex justify-center mt-5 text-sm">
                    <p className="text-gray-400">or you can sign with</p>
                </div>
                <div className="flex justify-center gap-3 mt-3" onClick={handleLoginMs}>
                    <img
                        src="/microsoft.png"
                        className="duration-300 scale-105 cursor-pointer h-7 grayscale hover:grayscale-0"
                    />
                </div>
                <div className="flex mt-5 text-sm text-center text-gray-400">
                    <p>
                        This site is protected by reCAPTCHA and the Google <br />
                        <a className="underline" href="">
                            Privacy Policy
                        </a>{' '}
                        and{' '}
                        <a className="underline" href="">
                            Terms of Service
                        </a>{' '}
                        apply.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default SignIn;
