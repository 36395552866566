import { useState } from 'react'
import { MUTATION_KEYS, QUERY_KEYS } from 'constants-es'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Col, message, Row, Switch } from 'antd'

import { UpdatePublishParams } from 'modules/mockup-detail/data/mutations/use-mutation-update-publish'
import updatePublish from 'modules/mockup-detail/data/mutations/use-mutation-update-publish/fetch'
import { MockupListingResponse } from 'modules/mockup-detail/data/queries'
import { PropsModalConfirm } from 'components/ui/modal-confirm'

type Props = {
    record?: MockupListingResponse
}

const ColumnPublish = ({ record }: Props) => {
    const queryClient = useQueryClient()

    const [modalConfirm, setModalConfirm] = useState<PropsModalConfirm>()

    const mutationUpdatePublish = useMutation({
        mutationFn: (params: UpdatePublishParams) => updatePublish(params),
        mutationKey: [MUTATION_KEYS.UPDATE_PUBLISH]
    })

    const handlePublish = async () => {
        const result = await mutationUpdatePublish.mutateAsync({ ids: [record?.id] })
        if (result.success) {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.MOCKUP_LISTING] })
            setModalConfirm((prev) => ({ ...prev, isOpen: false }))
            message.success(result.message)
        } else {
            message.error(result.message)
        }
        // setModalConfirm({
        //     isOpen: true,
        //     title: 'Confirm',
        //     content: <div className='text-center'>Are you sure change publish status this item!</div>,
        //     onOk: async () => {
        //         const result = await mutationUpdatePublish.mutateAsync({ ids: [record?.id] })
        //         if (result.success) {
        //             queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.MOCKUP_DETAIL] })
        //             setModalConfirm((prev) => ({ ...prev, isOpen: false }))
        //             message.success(result.message)
        //         } else {
        //             message.error(result.message)
        //         }
        //     },
        //     onCancel: () => {
        //         setModalConfirm((prev) => ({ ...prev, isOpen: false }))
        //     },
        //     mutationKey: MUTATION_KEYS.UPDATE_PUBLISH
        // })
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Switch
                    unCheckedChildren='Publish'
                    checkedChildren='Publish'
                    checked={!!record?.catalogPublish}
                    onClick={handlePublish}
                    loading={mutationUpdatePublish.isPending}
                    disabled={!!record?.catalogPublish || record?.status !== 'Completed'}
                />
            </Col>
            {/* <ModalConfirmComponent {...modalConfirm} /> */}
        </Row>
    )
}

export default ColumnPublish