import { QUERY_KEYS } from 'constants-es';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import ChangeCase from 'utils/change-case';

import getImportRequest from './fetch';
import { ImportRequestRequest } from './interfaces';

export type TUseGetImportRequest = ImportRequestRequest & { enabled?: boolean }

export function useQueryGetImportRequest({ enabled = true, ...rest }: TUseGetImportRequest) {

    return useQuery({
        queryKey: [QUERY_KEYS.IMPORT_REQUEST, rest],
        queryFn: () => getImportRequest(rest),
        select: (res) => {
            return {
                ...res,
                data: ChangeCase.object.toCamelCase(res.data)
            }
        },
        placeholderData: (previousData, _previousQuery) => previousData,
        enabled,
    });
}

export * from './interfaces';

